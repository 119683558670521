import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm} from '../generics';
import {FormInput, FormTextArea} from '../ui';
import {reverse} from '../utils.js';


export default class FirmBasePage extends Component {
    tableCheckCallback = (checkedIds) => {

    }
    
    render() {
        let api = new API('invoiceFirm');
        let permissions = this.props.permissions.invoicefirm;

        return (
            <GenericBasePage 
                title="Firm"
                api={api}
                instance={this.props.instance}
                list_url={URLS.invoiceFirm_all}
                add_url={URLS.invoiceFirm_add}
                edit_url={URLS.invoiceFirm_edit}
                tableRowURI={URLS.invoiceFirm_edit}
                tableCols={['name', 'invoice_id_prefix']}
                tableTh={['Name', 'ID code']}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <FirmForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                editForm={(initialData) => <FirmForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}


/*function BookTableRowActions(props) {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        alert("show file ID: " + this.props.rowId);
    }

    render() {
        return (
            <Button className='link' onClick={this.handleClick}><File /> View PDF</Button>
        );
    }
    return (
        <ReverseLink to={URLS.challan_edit} args={{id: props.rowId, instance: props.instance.slug}}><File /> PDF</ReverseLink>
    );
}
*/

//const BookTableRowActions = withRouter(_BookTableRowActions);


class _FirmForm extends Component {
    constructor(props) {
        super(props);

        let initialData = {
            instance: props.instance.id,
            name: '',
            invoice_id_prefix: '',
            phone: '',
            email: '',
            address: '',
            gstin: '',
            msme: '',
            bank_name: '',
            acc_no: '',
            ifs_code: '',
            pan_no: '',
            signature: '',

            ...props.initialData
        };

        this.state = {
            data: {...initialData},
            errors: {
                name: '',
                invoice_id_prefix: '',
                signature: ''
            }
        };
    }

    handleFormData = (formData) => {
        let data = formData;
        data.append('instance', this.state.data.instance);

        let isValid = true;
        let errors = {...this.state.errors};
        let required_fields = ['name', 'invoice_id_prefix'];

        let field;
        for (let i = 0; i < required_fields.length; i++) {
            field = required_fields[i];

            if (!data.get(field).length) {
                isValid = false;
                errors[field] = 'This field is required';
            } else {
                errors[field] = '';
            }
        }

        this.setState({errors: errors});

        return {data: data, isValid: isValid, dataType: 'form-data'};
    }

    setErrors = (errors) => {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit = (response, addAnother) => {
        let newPath = reverse(URLS.invoiceFirm_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname)
            this.props.history.push(newPath);
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.invoiceFirm_add);
    }

    afterDelete = (response) => {
        this.props.history.push(reverse(URLS.invoiceFirm_all, {instance: this.props.instance.slug}));
    }

    render() {
        return (
            <GenericForm 
                isNew={this.props.initialData ? false : true}
                permissions={this.props.permissions}
                instance={this.props.instance}
                entityId={this.props.initialData ? this.props.initialData.id : null}
                api={this.props.api}
                handleFormData={this.handleFormData}
                afterSubmit={this.afterSubmit}
                afterDelete={this.afterDelete}
                afterCancel={this.afterDelete}
                setErrors={this.setErrors}
            >
                <div className="col-md-8 col-lg-6">
                    <div className="row">
                        <div className="col-12">
                            <FormInput
                                type="text"
                                name="name"
                                label="Firm name"
                                placeholder="Firm name"
                                required={true}
                                defaultValue={this.state.data.name}
                                error={this.state.errors.name}
                            />
                            <FormInput
                                type="text"
                                name="invoice_id_prefix"
                                label="Invoice ID code"
                                placeholder="Invoice ID code"
                                helpText={'e.g. KA-SDA'}
                                required={true}
                                defaultValue={this.state.data.invoice_id_prefix}
                                error={this.state.errors.invoice_id_prefix}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <FormInput
                                type="text"
                                name="phone"
                                label="Phone"
                                placeholder="Phone"
                                defaultValue={this.state.data.phone}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormInput
                                type="text"
                                name="email"
                                label="Email"
                                placeholder="Email"
                                defaultValue={this.state.data.email}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormTextArea
                                name="address"
                                label="Address"
                                placeholder="Address"
                                rows={5}
                                defaultValue={this.state.data.address}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <FormInput
                                type="text"
                                name="gstin"
                                label="GSTIN"
                                placeholder="GSTIN"
                                defaultValue={this.state.data.gstin}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormInput
                                type="text"
                                name="msme"
                                label="MSME"
                                placeholder="MSME"
                                defaultValue={this.state.data.msme}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <FormInput
                                type="text"
                                name="bank_name"
                                label="Bank name"
                                placeholder="Bank name"
                                defaultValue={this.state.data.bank_name}
                            />
                        </div>
                        <div className="col-sm-6">
                            <FormInput
                                type="text"
                                name="acc_no"
                                label="Account no."
                                placeholder="Account no."
                                defaultValue={this.state.data.acc_no}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormInput
                                type="text"
                                name="ifs_code"
                                label="RTGS/IFS code"
                                placeholder="RTGS/IFS code"
                                defaultValue={this.state.data.ifs_code}
                            />
                            <FormInput
                                type="text"
                                name="pan_no"
                                label="PAN no."
                                placeholder="PAN no."
                                defaultValue={this.state.data.pan_no}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            {this.state.data.signature && 
                                <div>
                                <label>Signature</label>
                                <a href={this.state.data.signature} target="_blank" rel="noopener noreferrer">
                                    <img className="signature-thumbnail" src={this.state.data.signature} 
                                        alt="Current signature"
                                    />
                                </a>
                                </div>
                            }
                        </div>

                        <div className={this.state.data.signature ? "col-9" : "col-12"}>
                            <FormInput
                                type="file"
                                name="signature"
                                label={this.state.data.signature ? "Change" : "Signature"}
                                errors={this.state.errors.signature}
                            />
                        </div>
                    </div>
                </div>
            </GenericForm>
        );
    }
}

const FirmForm = withRouter(_FirmForm);
