import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Plus} from 'react-feather';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm, ObjectInfoFlyout} from '../generics';
import {FormInput, FormSelectInput, FormAsyncSelectInput, CloseButton, Button, FormRadioInput} from '../ui';
import {reverse} from '../utils.js';


export default class OrderBase extends Component {
    constructor(props) {
        super(props);

        this.filters = [
            {
                label: 'Status', name: 'completed', type: 'radio', 
                options: [
                    {label: 'All', value: '', default: true}, 
                    {label: 'Completed', value: 'true'}, 
                    {label: 'Pending', value: 'false'},
                ]
            },
            {
                label: 'Today', name: 'today', type: 'checkbox', 
                options: [
                    {label: 'Yes', value: 'true', default: false}, 
                ]
            },
            {
                label: 'Sorting', name: 'o', type: 'radio',
                options: [
                    {label: 'Newest first', value: '-created_on', default: true},
                    {label: 'Oldest first', value: 'created_on'},
                ]
            }
        ];
    }

    tableCheckCallback = (checkedIds) => {
        this.checkedIds = checkedIds.concat();
    }

    getTableCols = () => {
        if (this.props.instance.slug === 'rampur')
            return ['filename', 'completed', 'created_on', 'get_forward_to_display'];
        
        return ['filename', 'completed', 'created_on'];
    }

    getTableTh = () => {
        if (this.props.instance.slug === 'rampur')
            return ['Filename', 'Completed', 'Date created', 'Forward to'];
        
        return ['Filename', 'Completed', 'Date created'];
    }



    render() {
        let api = new API('order');
        let permissions = this.props.permissions.order;

        return (
            <GenericBasePage 
                title="Orders"
                api={api}
                instance={this.props.instance}
                list_url={URLS.order_all}
                add_url={URLS.order_add}
                edit_url={URLS.order_edit}
                listFilters={this.filters}
                tableRowURI={URLS.order_edit}
                tableCols={this.getTableCols()}
                tableTh={this.getTableTh()}
                tableColCls={['col-12', 'col-4', 'col-8', 'col-6']}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <OrderForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                editForm={(initialData) => <OrderForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}



class _OrderForm extends Component {
    constructor(props) {
        super(props);

        let initialData = {
            instance: props.instance.id,
            filename: '',
            ...props.initialData
        };

        if (!initialData.order_parts)
            initialData.order_parts = JSON.stringify([{width: "", width_unit: "", length: "", length_unit: "", qty: ""}]);

        if (!initialData.challan)
            initialData.challan = '';

        this.state = {
            instance: initialData.instance,
            filename: initialData.filename,
            challan: initialData.challan,
            errors: {
                filename: null,
                challan: null
            },
            orderParts: JSON.parse(initialData.order_parts),
            forward_to: initialData.forward_to || ''
        };

        this.state.orderPartsErrors = this.state.orderParts.map((part) => ({width: '', width_unit: '', length: '', length_unit: '', qty: ''}));

        this.handleFormData = this.handleFormData.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.afterDelete = this.afterDelete.bind(this);
        this.setErrors = this.setErrors.bind(this);

        if (initialData.challan.length > 0) {
            this.challanOptions = [{label: initialData.challan_name, value: initialData.challan}];
            this.state.challanOptionsLoading = false;
        }
        else {
            this.challanOptions = [];
            this.state.challanOptionsLoading = true;
        }

        this.challanApi = new API('challan');

    }

    componentDidMount() {
        this.getAsyncChallanOptions('', (options) => {
            this.challanOptions = this.challanOptions.concat(options);
            this.setState({challanOptionsLoading: false});
        });
    }

    onFilenameChange = (e) => {
        this.setState({filename: e.target.value, errors: {...this.state.errors, filename: null}});
    }

    onChallanChange = (val) => {
        this.setState({challan: val.value, errors: {...this.state.errors, challan: null}});
    }

    getAsyncChallanOptions = (inputValue, callback) => {
        let queryString = new URLSearchParams();

        queryString.set('instance', this.props.instance.id);
        queryString.set('trashed', false);
        queryString.set('designed', true);
        queryString.set('approved', true);
        queryString.set('q', inputValue);
        queryString.set('has_order', false);

        this.challanApi.getList(queryString.toString())
        .then((response) => {
            return callback(response.data.results.map( (val) => ({label: val.name, value: val.url}) ));
        });

    }

    handleFormData(formData) {
        let data = {
            instance: this.state.instance,
            filename: this.state.filename,
            challan: this.state.challan
        };

        let errors = {...this.state.errors};
        let isValid = true;

        if (data.filename.length < 1) {
            errors.filename = 'This field is required';
            isValid = false;
        }

        if (data.challan.length < 1) {
            errors.challan = 'This field is required';
            isValid = false;
        }

        let orderPartsErrors = this.state.orderPartsErrors.concat();

        let required_orderPartFields = ['width', 'width_unit', 'length', 'length_unit', 'qty'];

        let order_parts = this.state.orderParts.map((part, index) => {
            for (let i = 0; i < required_orderPartFields.length; i++) {
                let field = required_orderPartFields[i];
                if (part[field].trim().length < 1) {
                    orderPartsErrors[index][field] = 'This field is required';
                }
            }
            return part;
        });

        for (let i = 0; i < orderPartsErrors.length; i++) {

            if (!isValid)
                break;

            let partError = orderPartsErrors[i];

            for (let field in partError) {
                if (partError.hasOwnProperty(field)) {
                    if (partError[field].trim().length > 0) {
                        isValid = false;
                        break;
                    }
                }
            }
        }

        //if (formData.get('forward_to') !== this.state.forward_to) {
            data['forward_to'] = formData.get('forward_to') || null;
        //}

        this.setState({
            errors: errors,
            orderPartsErrors: orderPartsErrors
        });

        return {data: {...data, order_parts: JSON.stringify(order_parts)}, isValid: isValid};
    }

    setErrors(errors) {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit(response, addAnother) {
        let newPath = reverse(URLS.order_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname)
            this.props.history.push(newPath);
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.order_add);
    }

    afterDelete(response) {
        this.props.history.push(reverse(URLS.order_all, {instance: this.props.instance.slug}));
    }

    addPart = () => {
        let newPart = [{width: '', width_unit: '', length: '', length_unit: '', qty: ''}];
        let newPartError = [{width: '', width_unit: '', length: '', length_unit: '', qty: ''}];

        this.setState({
            orderParts: this.state.orderParts.concat(newPart),
            orderPartsErrors: this.state.orderPartsErrors.concat(newPartError)
        });
    }

    removePart = (idx) => {
        if (this.state.orderParts.length === 1) {
            return;
        }

        this.setState({
            orderParts: this.state.orderParts.filter((service, index) => idx !== index),
            orderPartsErrors: this.state.orderPartsErrors.filter((error, index) => idx !== index)
        });
    }

    handlePartChange = (e, index) => {
        let errors = [...this.state.orderPartsErrors];

        let parts = this.state.orderParts.map((part, idx) => {
            if (idx === index) {
                let val = e.target.value.trim();
                let targetName = e.target.name;

                if (targetName.indexOf('width') > 0){
                    if (isNaN(val))
                        errors[index].width = 'Enter a number';
                    else
                        errors[index].width = '';
                    return {...part, width: val};
                }
                else if (targetName.indexOf('length') > 0){
                    if (isNaN(val))
                        errors[index].length = 'Enter a number';
                    else
                        errors[index].length = '';
                    return {...part, length: val};
                }
                else if (targetName.indexOf('qty') > 0){
                    if (isNaN(val))
                        errors[index].qty = 'Enter a number';
                    else
                        errors[index].qty = '';

                    return {...part, qty: val};
                }
            }
            return part;
        });

        this.setState({orderParts: parts, orderPartsErrors: errors});
    }

    handlePartUnitChange = (val, type, index) => {
        let errors = [...this.state.orderPartsErrors];

        let parts = this.state.orderParts.map((part, idx) => {
            if (idx === index) {
                if (type === 'width_unit') {
                    errors[index].width_unit = '';
                    return {...part, width_unit: val.value};
                } else if (type === 'length_unit') {
                    errors[index].length_unit = '';
                    return {...part, length_unit: val.value};
                }
            }

            return part;
        });

        this.setState({orderParts: parts, orderPartsErrors: errors});
    }

    render() {
        return (
            <GenericForm 
                isNew={this.props.initialData ? false : true}
                permissions={this.props.permissions}
                instance={this.props.instance}
                entityId={this.props.initialData ? this.props.initialData.id : null}
                api={this.props.api}
                handleFormData={this.handleFormData}
                afterSubmit={this.afterSubmit}
                afterDelete={this.afterDelete}
                afterCancel={this.afterDelete}
                setErrors={this.setErrors}
            >
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6">
                            <FormInput type="text" name="filename" placeholder="Filename" 
                                label="Filename"
                                required={true}
                                value={this.state.filename}
                                error={this.state.errors.filename}
                                onChange={this.onFilenameChange}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-9 col-md-6">
                            <FormAsyncSelectInput name="challan" 
                                label="Challan"
                                required={true}
                                defaultValue={this.state.challan}
                                error={this.state.errors.challan}
                                variant="fancy"
                                options={this.challanOptions}
                                onChangeCallback={this.onChallanChange}
                                isLoading={this.state.challanOptionsLoading}
                                loadOptions={this.getAsyncChallanOptions}
                                isClearable={true}
                            />
                        </div>

                        <ChallanInfo challan={this.state.challan} instance={this.state.instance} />

                    </div>

                    {this.props.instance.slug === 'rampur' &&
                        <div className="row">
                            <div className="col-12 col-sm-9 col-md-6">
                                <FormRadioInput 
                                    name="forward_to" 
                                    label="Forward to" 
                                    variant="inline"
                                    defaultValue={this.state.forward_to}
                                    options={[
                                        {label: 'None', value: ''},
                                        {label: 'Shimla', value: 1},
                                        {label: 'Chandigarh', value: 2},
                                    ]}
                                    required={false}
                                />
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-12">
                            <h3 className="form-section-header">Parts</h3>
                            <OrderPartInlines 
                                services={this.state.orderParts} 
                                errors={this.state.orderPartsErrors}
                                removeService={this.removePart}
                                onChange={this.handlePartChange}
                                onSelectChange={this.handlePartUnitChange}
                            />
                        </div>
                        <div className="col-12">
                            <Button 
                                className="btn-sm secondary" 
                                onClick={this.addPart}
                            >
                                <Plus /> Add Part
                            </Button>
                        </div>
                    </div>
                </div>

                {this.props.initialData ?
                    <div className="col-12 form-footer">
                        <p>Created on: {this.props.initialData.created_on}</p>
                    </div>
                    : null
                }

            </GenericForm>
        );
    }
}

const OrderForm = withRouter(_OrderForm);


function OrderPartInlines(props) {

    let units = [
        {label: "mm", value: "mm"},
        {label: "cm", value: "cm"},
        {label: "inch", value: "inch"},
        {label: "foot", value: "foot"},
        {label: "metre", value: "metre"}
    ];

    return props.services.map((service, index) => {
        return (
            <div className="form-row bordered dismissable" key={index}>
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-12">
                            <div className="row form-group-row">
                                <div className="col-6">
                                    <FormInput 
                                        name={"service_width_" + index}
                                        type="text" 
                                        placeholder="Width"
                                        label="Width"
                                        value={service.width} 
                                        onChange={(e) => props.onChange(e, index)}
                                        error={props.errors[index].width}
                                        required={true}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormSelectInput 
                                        name={"service_width_unit_" + index}
                                        label={" "}
                                        defaultValue={service.width_unit} 
                                        variant="fancy"
                                        options={units}
                                        onChangeCallback={(val) => props.onSelectChange(val, 'width_unit', index)}
                                        error={props.errors[index].width_unit}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-4 col-12">
                            <div className="row form-group-row">
                                <div className="col-6">
                                    <FormInput 
                                        name={"service_length_" + index}
                                        type="text" 
                                        placeholder="Length"
                                        label="Length"
                                        value={service.length} 
                                        onChange={(e) => props.onChange(e, index)}
                                        error={props.errors[index].length}
                                        required={true}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormSelectInput 
                                        name={"service_length_unit_" + index}
                                        label={" "}
                                        defaultValue={service.length_unit} 
                                        variant="fancy"
                                        options={units}
                                        onChangeCallback={(val) => props.onSelectChange(val, 'length_unit', index)}
                                        error={props.errors[index].length_unit}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2 col-12">
                            <FormInput 
                                name={"service_qty_" + index}
                                type="text" 
                                placeholder="Qty"
                                label="Qty"
                                value={service.qty} 
                                onChange={(e) => props.onChange(e, index)}
                                error={props.errors[index].qty}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="form-service-header">
                                Size: {service.width} {service.width_unit} X {' '}
                                {service.length} {service.length_unit} {' '}
                                &nbsp; | &nbsp; Qty: {service.qty}
                            </p>
                        </div>
                    </div>
                
                    {props.services.length === 1 ? null : <CloseButton onClick={() => props.removeService(index)} />}
                </div>
            </div>
        );
    });
}


class ChallanInfo extends Component {
    constructor(props) {
        super(props);

        this.api = new API('challan');
    }

    toRender(challan) {
        return (
            <div>
                <p><strong>Name:</strong> <br />{challan.name}</p>
                <p><strong>Description:</strong> <br />{challan.description}</p>
                <p><strong>Qty:</strong> {challan.qty}</p>
                <p><strong>Created on:</strong> <br />{challan.created_on}</p>
            </div>
        );
    }

    render() {
        return (
            <ObjectInfoFlyout
                title={'Challan details'}
                api={this.api}
                render={this.toRender}
                object={this.props.challan}
                queryString={'instance=' + this.props.instance}
            />
        );
    }
}
