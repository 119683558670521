var URLS = {
    home: '/:instance/',

    challan_all: '/:instance/challan/',
    challan_add: '/:instance/challan/add/',
    challan_edit: '/:instance/challan/:id(\\d+)/edit/',

    order_all: '/:instance/orders/',
    order_add: '/:instance/orders/add/',
    order_edit: '/:instance/orders/:id(\\d+)/edit/',

    invoice_all: '/:instance/invoice/',
    invoice_add: '/:instance/invoice/add/',
    invoice_edit: '/:instance/invoice/:id(\\d+)/edit/',

    quotation_all: '/:instance/quotation/',
    quotation_add: '/:instance/quotation/add/',
    quotation_edit: '/:instance/quotation/:id(\\d+)/edit/',

    invoiceFirm_all: '/:instance/firm/',
    invoiceFirm_add: '/:instance/firm/add/',
    invoiceFirm_edit: '/:instance/firm/:id(\\d+)/edit/',

    stockIn_all: '/:instance/stock-in/',
    stockIn_add: '/:instance/stock-in/add/',
    stockIn_edit: '/:instance/stock-in/:id(\\d+)/edit/',

    stockOut_all: '/:instance/stock-out/',
    stockOut_add: '/:instance/stock-out/add/',
    stockOut_edit: '/:instance/stock-out/:id(\\d+)/edit/',

    tool_all: '/:instance/tool/',
    tool_add: '/:instance/tool/add/',
    tool_edit: '/:instance/tool/:id(\\d+)/edit/',

    report_all: '/:instance/reports/',

    user_all: '/:instance/user/',
    user_add: '/:instance/user/add/',
    user_edit: '/:instance/user/:id(\\d+)/edit/',

    task_all: '/:instance/task/',
    task_add: '/:instance/task/add/',
    task_edit: '/:instance/task/:id(\\d+)/edit/',

    group_all: '/:instance/group/',
    group_add: '/:instance/group/add/',
    group_edit: '/:instance/group/:id(\\d+)/edit/',
};

export {URLS};