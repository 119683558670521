import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {RotateCw} from 'react-feather';
import {GenericPageHeader} from '../generics';
import {toast} from '../ui';
import {reverse} from '../utils.js';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';



export default class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading_orders: true,
            orders_new: '0',
            orders_completed: '0',
            orders_pending: '0',

            loading_tasks: true,
            tasks_new: '0',
            tasks_completed: '0',
            tasks_pending: '0',
        }

        this.api = new API('home');
    }

    componentDidMount() {
        document.body.classList.add('homepage-body');

        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.instance.slug !== prevProps.instance.slug) {
            this.setState(
                {loading_orders: true, loading_tasks: true}, 
                this.fetchData
            );
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('homepage-body');
    }

    fetchData = (view) => {
        toast.hideError();

        let queryString = 'instance=' + this.props.instance.id;
        if (view)
            queryString += '&view=' + view;
        
        this.api.getList(queryString)
        .then((response) => {

            let newState = {};

            let variables = ['orders', 'tasks'];
            if (view)
                variables = [view];

            for (let i = 0; i < variables.length; i++) {
                let variable = variables[i];
                newState['loading_' + variable] = false;
                newState[variable + '_new'] = response.data[variable].new;
                newState[variable + '_completed'] = response.data[variable].completed;
                newState[variable + '_pending'] = response.data[variable].pending;
            }

            this.setState(newState);
        })
        .catch((error) => {
            this.setState({loading_orders: false, loading_tasks: false});
            toast.error("Something went wrong. Check your connection.");
        });
    }


    refreshOrders = (e) => {
        this.setState({loading_orders: true});
        this.fetchData('orders');
    }

    refreshTasks = (e) => {
        this.setState({loading_tasks: true});
        this.fetchData('tasks');
    }

    render() {
        return (
            <div className="main">
                <GenericPageHeader 
                    title={"Home"} 
                    hideSubnav={true}
                    location={this.props.location}
                />

                <div className="content">
                    <h3 className="homepage-heading">{this.props.instance.name} branch overview</h3>

                    <div className="row">
                        <Panel 
                            title="Orders" 
                            className="col-12 col-md-6"
                            onRefreshClick={this.refreshOrders}
                        >
                            <PanelLink title="New today" data={this.state.orders_new} loading={this.state.loading_orders} 
                                link={reverse(URLS.order_all, {instance: this.props.instance.slug}) + '?today=true'} 
                            /> 
                            <PanelLink title="Completed today" data={this.state.orders_completed} loading={this.state.loading_orders} 
                                link={reverse(URLS.order_all, {instance: this.props.instance.slug}) + '?completed=true&today=true'} 
                            /> 
                            <PanelLink title="Total pending" data={this.state.orders_pending} loading={this.state.loading_orders} 
                                link={reverse(URLS.order_all, {instance: this.props.instance.slug}) + '?completed=false'} 
                            />
                        </Panel>
                    </div>

                    <div className="row">
                        <Panel 
                            title="Tasks" 
                            className="col-12 col-md-6"
                            onRefreshClick={this.refreshTasks}
                        >
                            <PanelLink title="New today" data={this.state.tasks_new} loading={this.state.loading_tasks} 
                                link={reverse(URLS.task_all, {instance: this.props.instance.slug}) + '?today=true'} 
                            /> 
                            <PanelLink title="Completed today" data={this.state.tasks_completed} loading={this.state.loading_tasks} 
                                link={reverse(URLS.task_all, {instance: this.props.instance.slug}) + '?completed=true&today=true'} 
                            /> 
                            <PanelLink title="Total pending" data={this.state.tasks_pending} loading={this.state.loading_tasks} 
                                link={reverse(URLS.task_all, {instance: this.props.instance.slug}) + '?completed=false'} 
                            />
                        </Panel>
                    </div>
                </div>
            </div>
        );
    }
}

function Panel(props) {
    return (
        <div className={props.className || 'col-12'}>
            <div className="panel">
                <div className="panel-header">
                    <strong>{props.title}</strong>
                    {props.onRefreshClick && 
                        <button className="btn" onClick={props.onRefreshClick} title="Refresh">
                            <RotateCw />
                        </button>
                    }
                </div>
                <div className="row">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

function PanelFooter(props) {
    return (
        <div className="col-12 panel-footer">
            {props.children}
        </div>
    );
}

function PanelItem(props) {
    let className = props.className || 'col-6 col-sm-4';
    className += ' panel-item';

    return (
        <div className={className}>
            <p className="text-muted"><small>{props.title}</small></p>
            {props.data !== null && <h2>{props.data}</h2>}
            {props.children}
        </div>
    );
}

function PanelLink(props) {
    let className = props.className || 'col-6 col-sm-4';
    className += ' panel-item';

    return (
        <Link className={className} to={props.link}>
            <p className="text-muted"><small>{props.title}</small></p>
            {props.loading ? 
                <div className="placeholder ph-line"></div>
                :
                <>
                {props.data !== null && <h2>{props.data}</h2>}
                {props.children}
                </>
            }
        </Link>
    );
}