import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Plus, Edit} from 'react-feather';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm, GenericFlyoutForm} from '../generics';
import {FormInput, FormInputGroup, Button, Table, Pagination, toast, FormRadioInput, 
    Loader} from '../ui';
import {reverse} from '../utils.js';

export default class ToolBase extends Component {
    constructor(props) {
        super(props);

        this.filters = [
            {
                label: 'Sorting', name: 'o', type: 'radio',
                options: [
                    {label: 'Name (A-Z)', value: 'name', default: true},
                    {label: 'Name (Z-A)', value: '-name'},
                ]
            }
        ];
    }

    tableCheckCallback = (checkedIds) => {
        
    }

    render() {
        let api = new API('tool');
        let permissions = this.props.permissions.tool;

        return (
            <GenericBasePage 
                title="Tools"
                api={api}
                instance={this.props.instance}
                list_url={URLS.tool_all}
                add_url={URLS.tool_add}
                edit_url={URLS.tool_edit}
                listFilters={this.filters}
                tableRowURI={URLS.tool_edit}
                tableCols={['name', 'initial_qty', 'issued_qty', 'remaining_qty']}
                tableTh={['Name', 'Initial Qty', 'Issued', 'Remaining']}
                tableColCls={['col-12', 'col-4', 'col-4', 'col-4']}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <ToolForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                editForm={(initialData) => <ToolForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}



class _ToolForm extends Component {
    constructor(props) {
        super(props);


        let initialData = {
            instance: props.instance.id,
            name: '',
            initial_qty: '',
            remaining_qty: '',
            ...props.initialData
        };

        this.state = {
            instance: initialData.instance,
            name: initialData.name,
            initial_qty: initialData.initial_qty,
            remaining_qty: initialData.remaining_qty,
            toolUrl: initialData.url,
            errors: {
                name: null,
                initial_qty: null,
                remaining_qty: null
            }
        };

        this.handleFormData = this.handleFormData.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.afterDelete = this.afterDelete.bind(this);
        this.setErrors = this.setErrors.bind(this);
    }

    handleFormData(formData) {
        let data = {
            instance: this.state.instance,
            name: formData.get('name'),
            initial_qty: formData.get('initial_qty'),
            remaining_qty: this.state.remaining_qty
        };

        let errors = {...this.state.errors};
        let isValid = true;

        if (data.name.length < 1) {
            errors.name = 'This field is required';
            isValid = false;
        } else {
            errors.name = null;
        }

        if (data.initial_qty.length < 1) {
            errors.initial_qty = 'This field is required';
            isValid = false;
        } else {
            errors.initial_qty = null;
        }

        if (data.remaining_qty.length < 1) {
            errors.remaining_qty = 'This field is required';
            isValid = false;
        } else {
            errors.remaining_qty = null;
        }

        this.setState({
            errors: errors
        });

        return {data: data, isValid: isValid};
    }


    setErrors(errors) {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit(response, addAnother) {
        let newPath = reverse(URLS.tool_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname) {
            if (!this.props.initialData) {
                newPath += '?o=-pk';
            }
            this.props.history.push(newPath);
        }
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.challan_add);
    }

    afterDelete(response) {
        this.props.history.push(reverse(URLS.tool_all, {instance: this.props.instance.slug}));
    }

    handleRemainingQtyChange = (e) => {
        this.setState({remaining_qty: e.target.value});
    }

    updateToolRemaining = (qty) => {
        this.setState({remaining_qty: qty});
    }

    render() {
        return (
            <>
                <GenericForm 
                    isNew={this.props.initialData ? false : true}
                    permissions={this.props.permissions}
                    instance={this.props.instance}
                    entityId={this.props.initialData ? this.props.initialData.id : null}
                    api={this.props.api}
                    handleFormData={this.handleFormData}
                    afterSubmit={this.afterSubmit}
                    afterDelete={this.afterDelete}
                    afterCancel={this.afterDelete}
                    setErrors={this.setErrors}
                >
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <FormInput type="text" name="name" placeholder="Tool name" 
                                    label="Tool name"
                                    defaultValue={this.state.name}
                                    error={this.state.errors.name}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <FormInput type="number" name="initial_qty" placeholder="Initial qty" 
                                    label="Initial qty"
                                    defaultValue={this.state.initial_qty}
                                    error={this.state.errors.initial_qty}
                                    required={true}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <FormInput type="number" name="remaining_qty" placeholder="Remaining qty" 
                                    label="Remaining qty"
                                    value={this.state.remaining_qty}
                                    onChange={this.handleRemainingQtyChange}
                                    error={this.state.errors.remaining_qty}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                </GenericForm>
                {this.props.initialData
                    ? <ToolLogSection 
                        instance={this.props.instance}
                        tool={{
                            url: this.state.toolUrl, 
                            id: this.props.initialData.id,
                            name: this.state.name, 
                            instance: this.props.initialData.instance
                        }}
                        location={this.props.location}
                        history={this.props.history}
                        handleLogout={this.props.handleLogout}
                        permissions={this.props.permissions}
                        updateToolRemaining={this.updateToolRemaining}
                    />
                    : null
                }
            </>
        );
    }
}

const ToolForm = withRouter(_ToolForm);


class ToolLogSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showFlyout: false,
            isLoading: true,
            hasError: false,
            errorType: null,
            filterApplied: false,
            limit: 10,
            data: {count: 0, results: []},
            editing: false,
            filterState: new URLSearchParams(this.props.location.search).get('returned') ? '2' : '1',
            flashRows: null
        };

        this.api = new API('toolLog');

        this.sectionHeaderRef = React.createRef();
    }

    onAddLogClick = (e) => {
        this.setState({showFlyout: true, editing: false});
    }

    onEditClick = (rowId) => {
        this.setState({
            showFlyout: true, 
            editing: this.state.data.results.find((item) => item.id === rowId)
        });
    }

    handleCloseFlyout = (e) => {
        this.setState({showFlyout: false, editing: false});
    }

    scrollToLogTable = () => {
        this.sectionHeaderRef.current.scrollIntoView(true);
        window.scrollBy(0, -70);
    }

    handleFilterClick = (e) => {
        if (e.target.value === '2') {
            this.props.history.push(this.props.location.pathname + '?returned=false');
        } else {
            this.props.history.push(this.props.location.pathname);
        }
        this.setState({filterState: e.target.value});
    }

    queryApi = () => {
        let queryString = new URLSearchParams(this.props.location.search);
        let firstLoad = queryString.get('page') ? false : true;
        let page = Number(queryString.get('page')) || 1;

        let limit = this.state.limit;
        let offset = (page - 1) * limit;

        queryString.delete('page');
        queryString.set('limit', limit);
        queryString.set('offset', offset);

        queryString.set('tool', this.props.tool.id);

        queryString.set('instance', this.props.instance.id);

        this.api.getList(queryString.toString())
        .then((response) => {
            this.setState({isLoading: false, data: response.data});
            if (!firstLoad) {
                this.scrollToLogTable();
            }
        })
        .catch((error) => {
            let errorType = 'CONNECTION_ERROR';

            if (error.response)
                errorType = error.response.status;

            if (error.response.status === 401) {
                this.props.handleLogout(true);
            } else {
                this.setState({isLoading: false, hasError: true, errorType: errorType});
                toast.showConnectionError(this.retryApi);
            }

        });
    }

    retryApi = () => {
        this.setState({isLoading: true, hasError: false});
        this.queryApi();
    }

    getCurrentPage = () => {
        let params = new URLSearchParams(this.props.location.search);
        let page = Number(params.get('page')) || 1;
        return page;
    }

    componentDidMount() {
        //window.scrollTo(0, 0);

        this.queryApi();

        toast.showLoader();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search
            || this.props.refresh !== prevProps.refresh
            || this.props.instance !== prevProps.instance) {
            this.setState({isLoading: true});
            //:TODO: cancel previous api request before sending new one
            this.queryApi();
        }

        if (this.state.isLoading !== prevState.isLoading) {
            if (this.state.isLoading)
                toast.showLoader();
            else
                toast.hideLoader();
        }
    }

    componentWillUnmount() {
        toast.hideLoader();
    }

    afterSaveLog = (response) => {
        if (this.state.editing) {
            let newFlashRows;
            let newResults = this.state.data.results.map((item, index) => {
                if (item.id === response.data.id) {
                    newFlashRows = [index];
                    return response.data;
                }
                return item;
            });

            this.setState({
                data: {...this.state.data, results: newResults},
                showFlyout: false, editing: false,
                flashRows: newFlashRows
            }, () => {
                setTimeout(() => this.setState({flashRows: []}), 1550)
            });
        } else {
            let newResults = [response.data].concat(this.state.data.results);
            let newCount = newResults.length;

            this.setState({
                    data: {...this.state.data, count: newCount, results: newResults},
                    showFlyout: false, editing: false,
                    flashRows: [0]
                }, 
                () => {
                    setTimeout(() => this.setState({flashRows: []}), 1550);
                    this.scrollToLogTable();
                }
            );
        }
    }

    afterDeleteLog = (response) => {
        let newResults = this.state.data.results.filter((item) => {
            console.log(item.id, this.state.editing.id)
            return item.id !== this.state.editing.id;
        });

        let newCount = newResults.length;

        this.setState({
            data: {...this.state.data, count: newCount, results: newResults},
            showFlyout: false, editing: false,
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="content">
                    <div className="table-container" style={{marginBottom: '20rem'}}>
                        <h3 className="table-container-header">Logs</h3>

                        <div className="text-center table-outer">
                            <Loader />
                            <h3>Loading</h3>
                            <br />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="content">
                <div className="table-container">
                    <h3 className="table-container-header" ref={this.sectionHeaderRef}>Logs</h3>
                    <div className="toolbar">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <Button className="secondary" onClick={this.onAddLogClick}><Plus /> Add Log</Button>
                            </div>
                            <div className="col-12 col-md-5 text-right toolbar-buttons">
                                <FormRadioInput name="gender"
                                    options={[
                                        {label: 'All', value: '1'},
                                        {label: 'Not Returned', value: '2'}
                                    ]}
                                    variant="inline"
                                    defaultValue={this.state.filterState}
                                    onChangeCallback={this.handleFilterClick}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="table-outer">
                        <Table
                            instance={this.props.instance} 
                            data={this.state.data.results}
                            tableCols={['issued_to', 'qty', 'issue_date_display', 'returned', 'return_date_display']}
                            tableTh={['Issued to', 'Qty', 'Issue date', 'Returned', 'Return date']}
                            tableColCls={['col-12', 'col-6', 'col-6', 'col-6']}
                            tableRowActions={(row) => <Button className="link" onClick={() => this.onEditClick(row.id)}><Edit /> Edit</Button>}
                            tableCheckable={false}
                            flashRows={this.state.flashRows}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mb-3">
                        <Pagination 
                            instance={this.props.instance}
                            total_count={this.state.data.count}
                            per_page={this.state.limit}
                            currentPage={this.getCurrentPage()}
                        />
                    </div>
                </div>

                <ToolLogForm 
                    isOpen={this.state.showFlyout}
                    tool={this.props.tool}
                    handleCloseFlyout={this.handleCloseFlyout}
                    api={this.api}
                    permissions={this.props.permissions}
                    editing={this.state.editing}
                    updateToolRemaining={this.props.updateToolRemaining}
                    afterSaveLog={this.afterSaveLog}
                    afterDeleteLog={this.afterDeleteLog}
                    instance={this.props.instance}
                />
            </div>
        );
    }
}


class ToolLogForm extends Component {
    constructor(props) {
        super(props);

        this.blankState = {
            issued_to: '',
            qty: '',
            return_date: '',
        }

        this.state = {
            submitting: false,
            hasError: false,
            errorType: null,
            ...this.blankState,
            errors: {...this.blankState}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.editing && (prevProps.editing !== this.props.editing)) {
            this.setState({
                issued_to: this.props.editing.issued_to,
                qty: this.props.editing.qty,
                return_date: this.props.editing.return_date || ''
            });
        }
    }

    handleIssuedToChange = (e) => {
        this.setState({
            issued_to: e.target.value,
            errors: {...this.state.errors, issued_to: null}
        });
    }

    handleQtyChange = (e) => {

        let error = null;
        let value = e.target.value.trim();

        if (isNaN(value)) {
            error = 'Enter a number';
        }

        this.setState({
            qty: value, 
            errors: {...this.state.errors, qty: error}
        });
    }

    handleReturnDateChange = (e) => {
        this.setState({
            return_date: e.target.value, 
            errors: {...this.state.errors, return_date: null}
        });
    }

    setTodayDate = (e) => {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;

        day = day > 10 ? day : '0' + day;
        month = month > 10 ? month : '0' + month;

        this.setState({return_date: date.getFullYear() + '-' + month + '-' + day});
    }

    handleFormData = () => {
        let data, required_fields;

        if (this.props.editing) {
            data = {
                return_date: this.state.return_date
            };
            required_fields = ['return_date'];
        } else {
            data = {
                instance: this.props.tool.instance,
                tool: this.props.tool.url,
                issued_to: this.state.issued_to.trim(),
                qty: this.state.qty
            };
            required_fields = ['issued_to', 'qty'];
        }

        let isValid = true;

        let errors = {...this.state.errors};

        for (let i = 0; i < required_fields.length; i++ ) {
            let field = required_fields[i];

            if (!data[field]) {
                isValid = false;
                errors[field] = 'This field is required';
            }
        }

        if (!isValid)
            this.setErrors(errors);

        return {data: {...data}, isValid: isValid};
    }

    setErrors = (errors) => {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors, submitting: false});
    }

    afterSubmit = (response, addAnother) => {
        /*
            1. close flyout
            2. reset form
            3. scroll down to Logs header
            4. update log table
        */

        //this.handleCloseFlyout();

        this.props.updateToolRemaining(response.data.remaining_tool_qty);
        this.props.afterSaveLog(response);

        this.setState({...this.state, ...this.blankState, errors: {...this.blankState}});

    }

    afterDelete = (response) => {
        /*
            1. close flyout
            2. reset form ?? should that be done because how will you adjust qty?
            3. update log table
        */

        this.props.afterDeleteLog(response);

        this.setState({...this.state, ...this.blankState, errors: {...this.blankState}});
    }

    handleCloseFlyout = () => {
        this.setState({...this.state, ...this.blankState, errors: {...this.blankState}});
        this.props.handleCloseFlyout();
    }

    render() {
        let returnDateInput;

        if (this.props.editing && !this.props.editing.return_date) {
            returnDateInput = (
                <FormInputGroup
                    type="date"
                    name="return_date"
                    label="Return date"
                    placeholder="dd/mm/yyyy"
                    value={this.state.return_date}
                    onChange={this.handleReturnDateChange}
                    error={this.state.errors.return_date}
                    required={true}
                    append={<Button type="button" className="link" onClick={this.setTodayDate}>Today</Button>}
                />
            );
        } else if (this.props.editing && this.props.editing.return_date) {
            returnDateInput = (
                <FormInput
                    type="text"
                    name="return_date"
                    label="Return date"
                    placeholder="dd/mm/yyyy"
                    value={this.state.return_date}
                    onChange={this.handleReturnDateChange}
                    error={this.state.errors.return_date}
                    required={true}
                    readOnly={true}
                />
            );
        }


        return (
                <GenericFlyoutForm 
                    isNew={this.props.editing ? false : true}
                    permissions={{...this.props.permissions, can_delete: false}}
                    instance={this.props.instance}
                    entityId={this.props.editing ? this.props.editing.id : null}
                    api={this.props.api}
                    handleFormData={this.handleFormData}
                    afterSubmit={this.afterSubmit}
                    afterDelete={this.afterDelete}
                    setErrors={this.setErrors}
                    className='flyout-form'
                    hideBtns={true}

                    isOpen={this.props.isOpen}
                    handleCloseFlyout={this.handleCloseFlyout}
                    title={this.props.editing ? "Edit log" : "Add log"}

                >
                        <div className="col-12">
                            <FormInput
                                type="text"
                                name="name"
                                label="Tool"
                                placeholder="Tool"
                                value={this.props.tool.name}
                                readOnly={true}
                            />
                            <FormInput
                                type="text"
                                name="issued_to"
                                label="Issued to"
                                placeholder="Issued to"
                                value={this.state.issued_to}
                                onChange={this.handleIssuedToChange}
                                error={this.state.errors.issued_to}
                                required={true}
                                readOnly={this.props.editing && true}
                            />
                            <FormInput
                                type="text"
                                name="qty"
                                label="Qty"
                                placeholder="Qty"
                                value={this.state.qty}
                                onChange={this.handleQtyChange}
                                error={this.state.errors.qty}
                                required={true}
                                readOnly={this.props.editing && true}
                            />
                            {returnDateInput}
                        </div>
                </GenericFlyoutForm>
        );
    }
}

