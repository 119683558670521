import React, { Component } from 'react';
import {Route, Switch, BrowserRouter, withRouter} from 'react-router-dom';
// import axios from 'axios';
import {
    HomePage, OrderPage, ChallanPage, InvoicePage, QuotationPage, LoginPage, ToolPage, 
    FirmPage, StockInPage, StockOutPage, ReportPage, UserPage, TaskPage
} from './pages';
import {SideNav, ToastContainer, DropdownButton, Loader, AddToHome, toast} from './ui';
import {URLS} from './urlconf.js';
import {API} from './api.js';
import {User, Globe} from 'react-feather';

import './App.scss';


function Splash(props) {
    return (
        <div className="splash">
            <img src={process.env.PUBLIC_URL + "/img/logo2b.png"} alt="Kawasaki Advertisers" />
            
            {/* pre load error image for service worker caching */}
            <img src={process.env.PUBLIC_URL + "/img/error-icon.png"} alt="Error" style={{width: 0, height: 0, opacity: 0}}/>
            
            <Loader />
        </div>
    );
}

function ErrorSplash(props) {
    return (
        <div className="splash text-center">
            <img src={process.env.PUBLIC_URL + "/img/error-icon.png"} alt="Error" />
            <h2>{props.msg || 'Something went wrong. Try again'}</h2>
            {
                props.retryCallback ? 
                <p>
                    <button className="btn link" onClick={props.retryCallback}>
                        {props.retryBtnText || 'Try Again'}
                    </button>
                </p> 
                : 
                null
            }
        </div>
    );
}


class _App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            currentInstanceIndex: 0,
            instances: null,
            loggedIn: false,
            errorMsg: '',
            user: null,
            permissions: null
        };

        this.handleInstanceChange = this.handleInstanceChange.bind(this);
        this.makeInitRequest = this.makeInitRequest.bind(this);
    }

    makeInitRequest() {
        let api = new API('init');

        api.getList('instance=public')
        .then((response) => {
            // if user not logged in, redirect to login page
            if (!response.data.user.authenticated) {
                document.cookie = 'auth_token=; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
                api.setToken(null);
                this.redirectToLogin();

                this.setState({
                    isLoading: false,
                    errorMsg: ''
                });

            } else {
                this.setState({
                    user: response.data.user,
                    permissions: response.data.permissions,
                    errorMsg: ''
                });
                this.fetchInstances();
            }
        })
        .catch((error) => {
            let errorType = 'CONNECTION_ERROR';

            if (error.response)
                errorType = error.response.status;

            // if 401 status code; that means the token has expired or user not logged in
            // need to redirect user to login page
            if (errorType === 401) {
                document.cookie = 'auth_token=; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
                api.setToken(null);
                this.redirectToLogin();
            } else if (errorType === 'CONNECTION_ERROR') {
                this.setState({errorMsg: "Check your internet connection"})
            }

            this.setState({isLoading: false});
            //this.setState({isLoading: false, hasError: true, errorType: errorType});

            //toast.showConnectionError(this.retryApi);
        });
    }

    redirectToNext = () => {
        let next = new URLSearchParams(this.props.location.search).get('next') || '/';

        if (next === '/' && this.state.instances) {
            next = '/' + this.state.instances[this.state.currentInstanceIndex].slug + '/';
        }

        this.props.history.push(next);
    }

    redirectToLogin = () => {
        let next = this.props.location.search;

        if (this.props.location.pathname.indexOf('login') === -1)
            next = '?next=' + this.props.location.pathname;

        this.props.history.push('/login/' + next);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loggedIn && this.props.match.params.instance === 'login') {
            this.redirectToNext();
            return;
        }

        if (!this.props.match.params.instance) {
            if (this.state.instances)
                this.redirectToNext();
            else {
                this.redirectToLogin();
            }
            return;
        }

        if (this.state.instances && !isNaN(this.state.currentInstanceIndex)) {
            if (this.props.match.params.instance !== this.state.instances[this.state.currentInstanceIndex].slug) {
                let index = this.state.instances.findIndex((value) => value.slug === this.props.match.params.instance);
                if (index > -1) {
                    this.setState({currentInstanceIndex: index});
                }
            }
        }

    }

    componentDidMount() {
        this.makeInitRequest();
    }

    handleInstanceChange(value, index) {
        let instance = this.state.instances[index];
        this.props.history.push('/' + instance.slug + '/');
        this.setState({currentInstanceIndex: index});
    }

    fetchInstances = () => {
        let api = new API('instance');

        api.getList('instance=public')
        .then((response) => {

            let instances = response.data.results;

            let currentInstanceIndex = 0;

            if (this.props.match.params.instance) {
                currentInstanceIndex = instances.findIndex((value) => value.slug === this.props.match.params.instance);

                if (currentInstanceIndex === -1)
                    currentInstanceIndex = 0;
            }

            this.setState({
                    currentInstanceIndex: currentInstanceIndex,
                    instances: instances,
                    loggedIn: true,
                    isLoading: false
                });
        })
        .catch((error) => {
            let errorMsg = 'Something went wrong. Check your internet connection and try again.'

            if (error.response) {
                if (error.response.status === 500)
                    errorMsg = "500 Server Error. Please contact administrator.";
            }

            this.setState({errorMsg: errorMsg, isLoading: false, retryMethod: 'fetchInstances'});
        });
    }

    onLogin = (token) => {
        document.cookie = 'auth_token=' + token + '; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';

        this.makeInitRequest();
    }

    handleLogout = (preserveNext) => {
        document.cookie = 'auth_token=; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
        let api = new API('init');
        api.setToken(null);

        if (preserveNext) {
            this.redirectToLogin();
        } else {
            this.props.history.push('/login/');
        }

        this.setState({loggedIn: false, user: null});
    }

    onLogout = () => {
        let api = new API('logout');

        api.post(api.endpoint + '?instance=public')
        .then((response) => {
            this.handleLogout(false);
        })
        .catch((error) => {
            if (!error.response)
                error.response = {};

            if (error.response.status === 401) {
                this.handleLogout(false);
            } else {
                toast.error("Something went wrong.");
            }
        });
    }

    retryApi = () => {
        this.setState({isLoading: true, errorMsg: ''});
        if (!this.state.user)
            this.makeInitRequest();
        else 
            this.fetchInstances();
    }

    render() {
        if (this.state.isLoading) {
            return <Splash />;
        }

        if (this.state.errorMsg) {
            return <ErrorSplash msg={this.state.errorMsg} retryCallback={this.retryApi} />;
        }

        if (!this.state.loggedIn) {
            return (<>
            <AddToHome />
            <LoginPage onLogin={this.onLogin} instance={this.state.instance} />
            </>);
        }

        if (this.state.instances) {
            let exists = this.state.instances.some((value) => this.props.match.params.instance === value.slug);
            if (!exists) {
                return <ErrorSplash msg="404: Page Not Found" retryCallback={this.redirectToNext} retryBtnText="Go To Home" />;
            }
        }

        if (!this.state.instances) {
            return null;
        }

        if (this.state.currentInstanceIndex === -1) {
            return (
                <div className="text-center">
                    <h1>404: Page Not Found</h1>
                    <p>This instance has been deleted or moved to another URL. Please check if the link is correct.</p>
                </div>
            );
        }

        let navlinks = [
            <DropdownButton 
                className="default"
                selectedIndex={this.state.currentInstanceIndex}
                icon={<Globe />}
                options={this.state.instances.map((value) => {
                    return {label: value.name, id: value.id, selectLabel: 'Branch: ' + value.name}
                })}
                onChange={(value, index) => this.handleInstanceChange(value, index)}
            >Branch: {this.state.instances[this.state.currentInstanceIndex].name} </DropdownButton>,
            <DropdownButton 
                className="default"
                placement="right"
                selectedIndex={-1}
                options={[
                    {label: "Logout", onSelect: (e) => this.onLogout()},
                ]}
            >
                <User className="nav-user-icon" /> {this.state.user.name}
            </DropdownButton>
        ];

        const currentInstance = this.state.instances[this.state.currentInstanceIndex];

        if (currentInstance.slug !== this.props.match.params.instance) {
            return null;
        }

        return (
                <div className="container-fluid">
                    <AddToHome />
                    <ToastContainer />
                    <SideNav 
                        navlinks={navlinks} 
                        instance={currentInstance.slug}
                    />
                    <div className="row">
                        <div className="col-md-10 offset-md-2 px-0">
                            <Switch>
                                <Route path={URLS.home} exact 
                                    render={
                                        (props) => <HomePage 
                                            {...props} 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />
                                <Route 
                                    path={URLS.challan_all} 
                                    render={
                                        () => <ChallanPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions} 
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />
                                <Route 
                                    path={URLS.order_all} 
                                    render={
                                        () => <OrderPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                        } 
                                />
                                <Route 
                                    path={URLS.invoice_all} 
                                    render={
                                        () => <InvoicePage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />
                                <Route 
                                    path={URLS.quotation_all} 
                                    render={
                                        () => <QuotationPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route 
                                    path={URLS.invoiceFirm_all} 
                                    render={
                                        () => <FirmPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route 
                                    path={URLS.stockIn_all} 
                                    render={
                                        () => <StockInPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route 
                                    path={URLS.stockOut_all} 
                                    render={
                                        () => <StockOutPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route 
                                    path={URLS.tool_all} 
                                    render={
                                        () => <ToolPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route 
                                    path={URLS.report_all} 
                                    render={
                                        (props) => <ReportPage 
                                            {...props}
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route 
                                    path={URLS.user_all} 
                                    render={
                                        () => <UserPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route 
                                    path={URLS.task_all} 
                                    render={
                                        () => <TaskPage 
                                            instance={currentInstance} 
                                            permissions={this.state.permissions}
                                            handleLogout={this.handleLogout}
                                        />
                                    } 
                                />

                                <Route
                                    render={() => (
                                        <div className="main" style={{marginTop: '2rem'}}>
                                            <ErrorSplash 
                                                msg="404: Page Not Found" 
                                                retryCallback={this.redirectToNext} 
                                                retryBtnText="Go To Home" 
                                            />
                                        </div>
                                        )
                                    }
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
        );
    }
}


const App = withRouter(_App);

export default function AppExport({props}) {
    return (
        <BrowserRouter>
            <Route path="/:instance?"
                render={() => <App {...props} />}
            />

            <div className="footer">
                version {process.env.REACT_APP_VERSION}
            </div>
        </BrowserRouter>
    );
};
