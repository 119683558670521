export var LENGTH_UNITS = [
    {label: "cm", value: "cm"},
    {label: "inch", value: "inch"},
    {label: "foot", value: "foot"},
    {label: "metre", value: "metre"},
];

export var QTY_UNITS = [
    {label: "gram", value: "gram"},
    {label: "kg", value: "kg"},
    {label: "ml", value: "ml"},
    {label: "litre", value: "litre"},
    {label: "mm", value: "mm"},
    {label: "cm", value: "cm"},
    {label: "inch", value: "inch"},
    {label: "foot", value: "foot"},
    {label: "metre", value: "metre"},
];

export var AREA_UNITS = [
    {label: "sq cm", value: "sq_cm"},
    {label: "sq inch", value: "sq_in"},
    {label: "sq foot", value: "sq_ft"},
    {label: "sq metre", value: "sq_m"},
];

export var CONVERSION_TABLES = {
    length: {
        mm: 1.0,
        cm: 10.0,
        metre: 1000.0,
        inch: 25.4,
        foot: 304.8,
    },
    area: {
        sq_mm: 1.0 * 1.0,
        sq_cm: 100.0,
        sq_m: 1000000.0,
        sq_in: 25.4 * 25.4,
        sq_ft: 304.8 * 304.8, 
    },
    weight: {
        gram: 1.0,
        kg: 1000.0,
    },
    volume: {
        ml: 1.0,
        litre: 1000.0,
    }
}

export function unitType(unit) {
    if (unit.startsWith('sq_'))
        return 'area';
    else if (CONVERSION_TABLES.volume.hasOwnProperty(unit))
        return 'volume';
    else if (CONVERSION_TABLES.weight.hasOwnProperty(unit))
        return 'weight';
    else if (CONVERSION_TABLES.length.hasOwnProperty(unit))
        return 'length';
    else
        return null;
}

export function unitsAreCompat(unit1, unit2) {
    let type1 = unitType(unit1);
    return type1 === unitType(unit2) && type1 !== null;
}

export function convertQty(mag, from_unit, to_unit) {
    let unit_table;

    unit_table = CONVERSION_TABLES[unitType(from_unit)];

    if (!unit_table)
        return "Unknown from_unit: " + from_unit;

    if (!unitsAreCompat(from_unit, to_unit))
        return "Incompatible units: " + from_unit + " and " + to_unit;

    let mag_base = unit_table[from_unit] * mag;

    let mag_to = mag_base / unit_table[to_unit];

    return mag_to;
}

export function getUnitLabel(unitType, value) {
    let unitList;

    if (unitType === 'area')
        unitList = AREA_UNITS;
    else if (unitType === 'length')
        unitList = LENGTH_UNITS;
    else if (unitType === 'qty')
        unitList = QTY_UNITS;

    if (!unitList)
        return null;

    let unit = unitList.find((i) => i.value === value);
    if (unit)
        return unit.label;

    return null;
}


//var area_categs = ['Roll', 'Acrylic', 'Laser Ply', 'MDF', 'Sunboard', 'Sun Pack', 'ACP Sheet'];
export var WIDTH_CATEGS = ['Roll',]// 'Acrylic', 'Laser Ply', 'MDF', 'Sunboard', 'Sun Pack', 'ACP Sheet'];
//var length_categs = ['Roll'];

export var TRACK_LENGTH = ['Roll'];
export var TRACK_AREA = ['Acrylic', 'Laser Ply', 'MDF', 'Sunboard', 'Sun Pack', 'ACP Sheet'];
export var TRACK_QTY = [
    'Drilling Bits', 'Tapes', 'Stud', 'Bond', 'Ink', 'Ilits', 'Rivets',
    'Tubes', 'Chalks', 'Screw', 'Dhoti', 'Cutter Blade', 'Welding', 'LED Power Supply', 
    'HDMI Wire', 'Spray Paint', 'Extension Board', 'Board Timer', 'Standee', 'Screws and Bolts',
    'Wires', 'LED'
];