import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {CSSTransitionGroup} from 'react-transition-group';
import {Button, RadioCheckInput} from '../ui';
import {withRouter} from 'react-router-dom';
import {Sliders, XCircle} from 'react-feather';


class GenericFilterButtons extends Component {
    constructor(props) {
        super(props);

        this.getFilterBtnClass = this.getFilterBtnClass.bind(this);
    }

    getFilterBtnClass() {
        let style = this.props.filterApplied ? 'primary' : 'default';
        let caret = this.props.toolsShown ? 'dropup' : 'dropdown';

        return 'btn ' + style + ' ' + caret;
    }

    render() {
        let clearBtn;

        if (this.props.filterApplied) {
            clearBtn = <button className="btn default" onClick={this.props.clearFilters}><XCircle /> Clear</button>
            
        }

        return (
            <>
                <button className={this.getFilterBtnClass()} onClick={this.props.toggleFilterTools}>
                    <Sliders /> Filters {this.props.filterApplied ? 'Applied' : null}
                </button>
                {clearBtn}
            </>
        );
    }
}

class GenericFilterTools extends Component {
    render() {
        if (!this.props.toolsRef.current || !this.props.isShown) {
            return null;
        }

        return ReactDOM.createPortal(
            this.props.children,
            this.props.toolsRef.current
        );
    }
}

class _GenericFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTools: false
        };

        this.getFilterState = this.getFilterState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleFilterTools = this.toggleFilterTools.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
    }

    getFilterState() {
        let searchParams = new URLSearchParams(this.props.location.search);

        let filters = {}

        for (let i = 0; i < this.props.filters.length; i++) {
            let filter = this.props.filters[i];

            if (filter.type === 'radio') {
                filters[filter.name] = searchParams.get(filter.name);
            } else if (filter.type === 'checkbox') {
                filters[filter.name] = searchParams.getAll(filter.name);
            }
        }

        return filters;
    }

    handleSubmit(e) {
        e.preventDefault();

        let queryString = new URLSearchParams(this.props.location.search);
        this.props.filters.map((filter) => {
            queryString.delete(filter.name);
            return null;
        });

        let formData = new FormData(e.target);
        for (let item of formData) {
            queryString.append(item[0], item[1]);
        }

        // go to page 1 of results
        queryString.delete('page');

        queryString = '?' + queryString.toString();


        if (queryString !== this.props.location.search) {
            this.props.history.push(this.props.location.pathname + queryString);
        }

        this.setState({showTools: false});
    }

    toggleFilterTools() {
        this.setState({showTools: !this.state.showTools});
    }

    clearFilters() {
        let oldQueryString = new URLSearchParams(this.props.location.search);

        this.props.filters.map((filter) => {
            if (oldQueryString.has(filter.name)) {
                oldQueryString.delete(filter.name);
            }
            return null;
        });

        this.props.history.push(this.props.location.pathname + '?' + oldQueryString.toString());
        this.setState({showTools: false});
    }

    render() {
        let filterState = this.getFilterState();
        let filterApplied = false;

        let filters = null;

        if (this.state.showTools) {
            filters = this.props.filters.map((filter) => {

                let options = filter.options.map((option) => {
                    let isChecked = false;

                    if (filter.type === 'radio') {
                        if (String(filterState[filter.name]) === option.value)
                            isChecked = true;
                    } else if (filter.type === 'checkbox') {
                        if (String(filterState[filter.name]).includes(option.value))
                            isChecked = true;
                    }

                    if (isChecked && !filterApplied)
                        filterApplied = true;

                    return (
                        <RadioCheckInput 
                            key={filter.name + '_option_' + option.value}
                            label={option.label}
                            type={filter.type} 
                            name={filter.name} 
                            value={option.value} 
                            defaultChecked={isChecked || option.default}
                        />
                    );
                });

                return (
                    <div className="col-12 col-md-4 col-sm-6" key={'filter_' + filter.name}>
                        <h4>{filter.label}</h4>
                        {options}
                    </div>
                );
            });
        } else {
            filterApplied = this.props.filters.some((filter) => {
                return filter.options.some((option) => {
                    if (filter.type === 'radio') {
                        if (filterState[filter.name] === option.value)
                            return true;
                    } else if (filter.type === 'checkbox') {
                        if (filterState[filter.name].includes(option.value))
                            return true;
                    }
                        
                    return false;
                    
                });
            });
        }

        return (
            <>
            <GenericFilterButtons 
                filterApplied={filterApplied} 
                toggleFilterTools={this.toggleFilterTools}
                clearFilters={this.clearFilters}
                toolsShown={this.state.showTools}
            />
            <CSSTransitionGroup
                transitionName="toolbar-tools"
                transitionEnterTimeout={100}
                transitionLeaveTimeout={100}
            >
                {filters ? <GenericFilterTools key="filterTools" toolsRef={this.props.toolsRef} isShown={this.state.showTools}>
                    <div className="toolbar-tools">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row filters-row">
                                {filters}
                            </div>
                            <div className="row filter-btns">
                                <div className="col-12">
                                    <Button className="primary" type="submit">Apply</Button>{' '}
                                    <Button className="default" type="button" onClick={this.toggleFilterTools}>Cancel</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </GenericFilterTools> : null}
            </CSSTransitionGroup>
            </>
        );
    }
}

export const GenericFilter = withRouter(_GenericFilter);

