import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import GenericPageHeader from './header.js';
import {GenericFilter} from './filters.js';
import {Table, RequestError, Pagination, toast, SkeletonTable, SkeletonForm} from '../ui';
import GenericListSearch from './search.js';


export class GenericBasePage extends Component {
    /*
        Generic base page component

        Props:
            Props accepted by GenericPageHeader
            Props accepted by GenericListPage
            Props accepted by GenericEditPage
            Props accepted by GenericAddPage

    */

    constructor(props) {
        super(props);

        this.state = {
            refresh: true
        };

        this.refreshPage = this.refreshPage.bind(this);
    }

    refreshPage() {
        this.setState({refresh: !this.state.refresh});
    }

    render() {
        return (
            <div className="main">
                <Route render={(props) => <GenericPageHeader {...props} 
                        title={this.props.title} 
                        list_url={this.props.list_url}
                        edit_url={this.props.edit_url}
                        add_url={this.props.add_url}
                        onRefresh={this.refreshPage}
                        instance={this.props.instance}
                        permissions={this.props.permissions}
                        />
                    } 
                />
                <Switch>
                    <Route path={this.props.list_url} exact 
                        render={(props) => <GenericListPage {...props}  
                            api={this.props.api} 
                            instance={this.props.instance}
                            listFilters={this.props.listFilters}
                            tableCols={this.props.tableCols}
                            tableTh={this.props.tableTh}
                            tableColCls={this.props.tableColCls}
                            tableThWidths={this.props.tableThWidths}
                            tableRowURI={this.props.tableRowURI}
                            tableRowActions={this.props.tableRowActions}
                            tableCheckable={this.props.tableCheckable}
                            tableCheckCallback={this.props.tableCheckCallback}
                            refresh={this.state.refresh}
                            permissions={this.props.permissions}
                            handleLogout={this.props.handleLogout}
                            />
                        } 
                    />
                    <Route path={this.props.add_url} exact 
                        render={(props) => <GenericAddPage {...props} 
                            api={this.props.api}
                            instance={this.props.instance}
                            form={this.props.addForm}
                            permissions={this.props.permissions}
                            handleLogout={this.props.handleLogout}
                            refresh={this.state.refresh}
                            />
                        } 
                    />
                    <Route path={this.props.edit_url} exact 
                        render={(props) => <GenericEditPage {...props}
                            api={this.props.api}
                            instance={this.props.instance}
                            form={this.props.editForm}
                            placeholderFields={this.props.placeholderFields}
                            refresh={this.state.refresh}
                            permissions={this.props.permissions}
                            handleLogout={this.props.handleLogout}
                            />
                        } 
                    />
                    <Route render={(props) => <RequestError errorType={404} />} />
                </Switch>
            </div>
        );
    }
}

export class GenericListPage extends Component {
    /*
        Generic list page component

        Props:
            api:    API instance.
            cols:   Columns to show.
            showTh: Show table header.
            th:     Table headers.
            rowUri: URL for row items.
            limit:  Number of items to display per page. 
    */

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasError: false,
            errorType: null,
            data: null,
            limit: 20,
            showTools: false,
            filterApplied: false
        }

        this.queryApi = this.queryApi.bind(this);
        this.retryApi = this.retryApi.bind(this);
        this.getCurrentPage = this.getCurrentPage.bind(this);

        this.toolbarToolsRef = React.createRef();
    } 

    queryApi() {
        if (!this.props.permissions.can_view) 
            return this.setState({isLoading: false});

        let queryString = new URLSearchParams(this.props.location.search);
        let page = Number(queryString.get('page')) || 1;

        let limit = this.state.limit;
        let offset = (page - 1) * limit;

        queryString.delete('page');
        queryString.set('limit', limit);
        queryString.set('offset', offset);

        queryString.set('instance', this.props.instance.id);

        this.props.api.getList(queryString.toString())
        .then((response) => {
            this.setState({isLoading: false, data: response.data});
            window.scrollTo(0, 0);
        })
        .catch((error) => {
            let errorType = 'CONNECTION_ERROR';

            if (error.response)
                errorType = error.response.status;
            else
                error.response = {};

            if (error.response.status === 401) {
                this.props.handleLogout(true);
            } else {
                this.setState({isLoading: false, hasError: true, errorType: errorType});
                toast.showConnectionError(this.retryApi);
            }

        });
    }

    retryApi() {
        this.setState({isLoading: true, hasError: false});
        this.queryApi();
    }

    getCurrentPage() {
        let params = new URLSearchParams(this.props.location.search);
        let page = Number(params.get('page')) || 1;
        return page;
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.queryApi();

        toast.showLoader();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search
            || this.props.refresh !== prevProps.refresh
            || this.props.instance !== prevProps.instance) {
            this.setState({isLoading: true});
            //:TODO: cancel previous api request before sending new one
            this.queryApi();
        }

        if (this.state.isLoading !== prevState.isLoading) {
            if (this.state.isLoading)
                toast.showLoader();
            else
                toast.hideLoader();
        }
    }

    componentWillUnmount() {
        toast.hideLoader();
    }

    render() {
        if (!this.props.permissions.can_view) {
            return (
                <div className="content text-error">
                    <h3>You don't have permission to view this page.</h3>
                </div>
            );
        }

        if (this.state.isLoading || !this.state.data) {
            return <div className="content"><SkeletonTable /></div>;
        }

        if (this.state.hasError && !this.state.data) {
            return (
                <div className="content">
                    <RequestError errorType={this.state.errorType} retryCallback={this.retryApi} />
                </div>
            );
        }

        return (
            <div className="content">
                <div className="table-container">
                    <div className="toolbar">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <GenericListSearch />
                            </div>
                            <div className="col-12 col-md-8 text-right toolbar-buttons">
                                {this.props.listFilters ? 
                                    <GenericFilter 
                                        filters={this.props.listFilters} 
                                        toolsRef={this.toolbarToolsRef} 
                                    /> : null
                                }
                            </div>
                        </div>
                        <div ref={this.toolbarToolsRef}></div>
                    </div>

                    <div className="table-outer">
                        <Table
                            instance={this.props.instance} 
                            data={this.state.data.results}
                            tableCols={this.props.tableCols}
                            tableTh={this.props.tableTh}
                            tableColCls={this.props.tableColCls}
                            tableThWidths={this.props.tableThWidths}
                            tableRowURI={this.props.tableRowURI}
                            tableRowActions={this.props.tableRowActions}
                            tableCheckable={this.props.tableCheckable}
                            tableCheckCallback={this.props.tableCheckCallback}
                        />
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <Pagination 
                            instance={this.props.instance}
                            total_count={this.state.data.count}
                            per_page={this.state.limit}
                            currentPage={this.getCurrentPage()}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export class GenericAddPage extends Component {
    /*
        Generic add page component.

        Props:
            form: A callback which returns a form component.

                  Functionally similar to "render props". 

                  Example:
                  
                  <GenericAddPage 
                      form={() => <MyForm />}
                  />

    */

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.refresh !== prevProps.refresh) {
            this.setState({isLoading: true}, () => setTimeout(this.setState({isLoading: false}), 10));
        }
    }

    render() {
        if (this.state.isLoading) 
            return null;
        
        if (!this.props.permissions.can_add) {
            return (
                <div className="content text-error">
                    <h3>You don't have permission to view this page.</h3>
                </div>
            );
        }

        return(
            <div className="content">
                {this.props.form()}
            </div>
        );
    }
}

export class GenericEditPage extends Component {
    /*
        Generic add page component.

        Props:
            form: A callback which returns a form component. The callback will 
                  be passed an argument called `initialData` containing the
                  data sent by the API. You can pass this data to the form component 
                  as a prop and later use it to pre-populate the form.

                  Functionally similar to "render props". 

                  Example:
                  
                  <GenericEditPage 
                      form={(initialData) => <MyForm initialData={initialData} />}
                  />

            api:  API instance. The object's ID is automatically taken from 
                    the page location and then passed to API methods where required.

    */

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasError: false,
            errorType: null,
            data: null,
        }

        this.queryApi = this.queryApi.bind(this);
        this.retryApi = this.retryApi.bind(this);
    } 

    componentDidMount() {
        window.scrollTo(0, 0);

        this.queryApi();

        toast.showLoader();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.refresh !== prevProps.refresh 
            || this.props.instance !== prevProps.instance) {
            this.setState({isLoading: true});
            this.queryApi();
        }

        if (this.state.isLoading !== prevState.isLoading) {
            if (this.state.isLoading) 
                toast.showLoader();
            else
                toast.hideLoader();
        }
    }

    componentWillUnmount() {
        toast.hideLoader();
        /*:TODO: 
            this will not hide error toasts.
        */
    }

    queryApi() {
        if (!this.props.permissions.can_view) 
            return this.setState({isLoading: false});

        let id = this.props.match.params.id;

        this.props.api.getOne(id, 'instance=' + this.props.instance.id)
        .then((response) => {
            this.setState({isLoading: false, data: response.data, hasError: false});
            toast.hideError();
        })
        .catch((error) => {
            let errorType = 'CONNECTION_ERROR';

            if (error.response)
                errorType = error.response.status;
                
            this.setState({isLoading: false, hasError: true, errorType: errorType});
            if (errorType === 'CONNECTION_ERROR')
                toast.showConnectionError(this.retryApi);
            else if (errorType === 404)
                toast.error("404: This page or object doesn't exist");
            else
                toast.error('Something went wrong')
        });
    }

    retryApi() {
        this.setState({isLoading: true, hasError: false});
        this.queryApi();
    }

    render() {
        if (!this.props.permissions.can_view) {
            return (
                <div className="content text-error">
                    <h3>You don't have permission to view this page.</h3>
                </div>
            );
        }


        if (this.state.isLoading) {
            return (
                <div className="content">
                    <div className="form-container">
                        <SkeletonForm fields={this.props.placeholderFields} />
                    </div>
                </div>
            );
        }

        if (this.state.hasError) {
            return (
                <div className="content">
                    <RequestError errorType={this.state.errorType} retryCallback={this.retryApi} />
                </div>
            );
        }

        return (
            <div className="content">
                {this.props.form(this.state.data)}
            </div>
        );
    }
}