import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Plus} from 'react-feather';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm} from '../generics';
import {FormInput, FormSelectInput, FormAsyncSelectInput, 
    CloseButton, Button} from '../ui';
import {reverse} from '../utils.js';
import {LENGTH_UNITS, QTY_UNITS, AREA_UNITS, 
    TRACK_LENGTH, TRACK_AREA, TRACK_QTY} from './units.js';


export default class StockOutBase extends Component {

    tableCheckCallback = (checkedIds) => {
        this.checkedIds = checkedIds.concat();
    }

    render() {
        let api = new API('stockLog');
        let permissions = this.props.permissions.stocklog;

        return (
            <GenericBasePage 
                title="Stock Out"
                api={api}
                instance={this.props.instance}
                list_url={URLS.stockOut_all}
                add_url={URLS.stockOut_add}
                edit_url={URLS.stockOut_edit}
                tableRowURI={URLS.stockOut_edit}
                tableCols={['name_display', 'date_display']}
                tableTh={['Log', 'Date']}
                tableThWidths={['', '175']}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <StockLogForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                editForm={(initialData) => <StockLogForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}



class _StockLogForm extends Component {
    constructor(props) {
        super(props);

        this.emptyLogPart = {
            category: "", name: "", usage: "", usage_unit: "", 
            wastage: "", wastage_unit: "", isNew: true, pk: null
        };

        this.emptyLogPartErrors = {
            category: "", name: "", usage: "", usage_unit: "", 
            wastage: "", wastage_unit: ""
        };

        let initialData = {
            instance: props.instance.id,
            orders: [],
            description: '',
            log_parts: JSON.stringify([this.emptyLogPart]),
            orders_options: [],
            ...props.initialData
        };

        this.state = {
            instance: initialData.instance,
            orders: initialData.orders,
            description: initialData.description,

            log_parts: JSON.parse(initialData.log_parts),

            errors: {
                orders: null,
                description: null,
                general: null
            }
        };

        this.state.logPartsErrors = this.state.log_parts.map((part) => ({...this.emptyLogPartErrors}));

        this.handleFormData = this.handleFormData.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.afterDelete = this.afterDelete.bind(this);
        this.setErrors = this.setErrors.bind(this);

        this.orderOptions = initialData.orders_options;

        if (this.orderOptions.length > 0) {
            this.state.orderOptionsLoading = false;
        }
        else {
            this.state.orderOptionsLoading = true;
        }


        this.stockOptions = [];

        this.orderApi = new API('order');
        this.stockApi = new API('stockItem');
    }

    componentDidMount() {
        this.getAsyncOrderOptions('', (options) => {
            this.orderOptions = this.orderOptions.concat(options);
            this.setState({orderOptionsLoading: false});
        });
    }

    onDescriptionChange = (e) => {
        this.setState({description: e.target.value, errors: {...this.state.errors, description: null}});
    }

    onOrderChange = (val) => {
        this.setState({orders: val.map((item) => item.value), errors: {...this.state.errors, orders: null}});
    }

    getAsyncOrderOptions = (inputValue, callback) => {
        let queryString = new URLSearchParams();

        queryString.set('instance', this.props.instance.id);
        queryString.set('q', inputValue);
        queryString.set('completed', false);
        queryString.set('limit', 200);

        this.orderApi.getList(queryString.toString())
        .then((response) => {
            this.updateOrderOptions(response.data.results);
            return callback(response.data.results.map( (val) => ({label: val.filename, value: val.url}) ));
        });

    }

    updateOrderOptions = (results) => {
        results.map((val) => {
            this.orderOptions = this.orderOptions.filter((i) => i.value !== val.url);
            return null;
        });
        this.setState({update: true});
    }

    getAsyncStockOptions = (inputValue, callback) => {
        let queryString = new URLSearchParams();

        queryString.set('instance', this.props.instance.id);
        queryString.set('q', inputValue);

        for (var i = 0; i < this.state.log_parts.length; i++) {
            if (this.state.log_parts[i].pk) {
                queryString.append('exclude', this.state.log_parts[i].pk);
            }
        }

        this.stockApi.getList(queryString.toString())
        .then((response) => {
            //let options = response.data.results.map( (val) => ({label: val.name_display, value: val.url}) );
            //this.stockOptions = this.stockOptions.concat(options);

            //this.setState({update: true});

            return callback(response.data.results.map( (val) => {
                return ({label: val.name_display, value: val.url, 
                    category: val.category_name, name: val.full_name, pk: val.id,
                    remaining: val.remaining,
                });
            }));
        });

    }

    handleFormData(formData) {
        let data = {
            instance: this.state.instance,
            description: this.state.description,
            orders: this.state.orders
        };

        let errors = {...this.state.errors};
        let isValid = true;

        if (data.description.length < 1 && data.orders.length < 1) {
            errors.description = 'This field is required';
            isValid = false;
        }

        let logPartsErrors = this.state.logPartsErrors.concat();

        //let required_logPartFields = ['name'];

        let logParts = JSON.parse(JSON.stringify(this.state.log_parts));

        let log_parts = logParts.map((part, index) => {
            let reqErr = 'This field is required';
            //let numErr = 'Enter a number';

            // remove unrequired keys
            delete part['remaining'];

            if (!part.name)
                logPartsErrors[index].name = reqErr;

            if (!part.usage)
                logPartsErrors[index].usage = reqErr;

            if (TRACK_AREA.includes(part.category) || TRACK_LENGTH.includes(part.category)) {
                if (!part.usage_unit)
                    logPartsErrors[index].usage_unit = reqErr;
            }

            if (part.usage_unit && part.wastage && !part.wastage_unit) {
                logPartsErrors[index].wastage_unit = reqErr;
            }

            return part;
        });

        for (let i = 0; i < logPartsErrors.length; i++) {

            if (!isValid)
                break;

            let partError = logPartsErrors[i];

            for (let field in partError) {
                if (partError.hasOwnProperty(field)) {
                    if (partError[field].trim().length > 0) {
                        isValid = false;
                        break;
                    }
                }
            }
        }

        this.setState({
            errors: errors,
            logPartsErrors: logPartsErrors
        });

        return {data: {...data, log_parts: JSON.stringify(log_parts)}, isValid: isValid};
    }

    setErrors(errors) {
        let {logPartsErrors, ...otherErrors} = errors;

        let newErrors = {...this.state.errors, ...otherErrors};

        let newLogPartsErrors = this.state.logPartsErrors.concat();
        for (let i = 0; i < logPartsErrors.length; i++) {
            newLogPartsErrors[i] = logPartsErrors[i];
        }

        this.setState({errors: newErrors, logPartsErrors: newLogPartsErrors});
    }

    afterSubmit(response, addAnother) {
        let newPath = reverse(URLS.stockOut_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname)
            this.props.history.push(newPath);
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.stockOut_add);
    }

    afterDelete(response) {
        this.props.history.push(reverse(URLS.stockOut_all, {instance: this.props.instance.slug}));
    }

    addPart = () => {
        let newPart = [{...this.emptyLogPart}];
        let newPartError = [{...this.emptyLogPartErrors}];

        this.setState({
            log_parts: this.state.log_parts.concat(newPart),
            logPartsErrors: this.state.logPartsErrors.concat(newPartError)
        });
    }

    removePart = (idx) => {
        if (this.state.log_parts.length === 1) {
            return;
        }

        this.setState({
            log_parts: this.state.log_parts.filter((service, index) => idx !== index),
            logPartsErrors: this.state.logPartsErrors.filter((error, index) => idx !== index)
        });
    }

    handlePartChange = (e, field, index) => {
        let errors = [...this.state.logPartsErrors];

        let parts = this.state.log_parts.map((part, idx) => {
            if (idx === index) {
                let val = e.target.value.trim();
                //let targetName = e.target.name;

                if (isNaN(val))
                    errors[index][field] = 'Enter a number';
                else
                    errors[index][field] = '';

                errors[index][field + '_unit'] = '';

                return {...part, [field]: val}
            }
            return part;
        });

        this.setState({log_parts: parts, logPartsErrors: errors});
    }

    handlePartUnitChange = (val, field, index) => {
        let errors = [...this.state.logPartsErrors];

        let parts = this.state.log_parts.map((part, idx) => {
            if (idx === index) {
                errors[index][field] = '';
                return {...part, [field]: val.value};
            }

            return part;
        });

        this.setState({log_parts: parts, logPartsErrors: errors});
    }

    handlePartItemChange = (val, index) => {
        let errors = [...this.state.logPartsErrors];

        let parts = this.state.log_parts.map((part, idx) => {
            if (idx === index) {
                errors[index].name = '';
                errors[index].usage = '';
                errors[index].usage_unit = '';
                errors[index].wastage = '';
                errors[index].wastage_unit = '';
                return ({...this.emptyLogPart, name: val.name || '', 
                    category: val.category || '', pk: val.pk,
                    remaining: val.remaining
                });
            }

            return part;
        });

        this.setState({log_parts: parts, logPartsErrors: errors});
    }

    render() {
        return (
            <GenericForm 
                isNew={this.props.initialData ? false : true}
                permissions={this.props.permissions}
                instance={this.props.instance}
                entityId={this.props.initialData ? this.props.initialData.id : null}
                api={this.props.api}
                handleFormData={this.handleFormData}
                afterSubmit={this.afterSubmit}
                afterDelete={this.afterDelete}
                afterCancel={this.afterDelete}
                setErrors={this.setErrors}
                setFullErrors={true}
            >
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-sm-9 col-md-6">
                            <FormSelectInput name="order"
                                label="Orders"
                                defaultValue={this.state.orders}
                                error={this.state.errors.orders}
                                variant="fancy"
                                options={this.orderOptions}
                                onChangeCallback={this.onOrderChange}
                                isLoading={this.state.orderOptionsLoading}
                                loadOptions={this.getAsyncOrderOptions}
                                isClearable={false}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                readOnly={this.props.initialData ? true : false}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-9 col-md-6">
                            <FormInput type="text" name="description" placeholder="Description" 
                                label="Description"
                                value={this.state.description}
                                error={this.state.errors.description}
                                onChange={this.onDescriptionChange}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h3 className="form-section-header">Items used</h3>
                            <LogPartInlines 
                                services={this.state.log_parts} 
                                errors={this.state.logPartsErrors}
                                removeService={this.removePart}
                                onChange={this.handlePartChange}
                                onSelectChange={this.handlePartUnitChange}
                                onStockItemChange={this.handlePartItemChange}
                                loadStockOptions={this.getAsyncStockOptions}
                                stockOptions={this.stockOptions}
                            />
                        </div>
                        <div className="col-12">
                            <Button 
                                className="btn-sm secondary" 
                                onClick={this.addPart}
                            >
                                <Plus /> Add Item
                            </Button>
                        </div>
                    </div>
                </div>

                {this.props.initialData ?
                    <div className="col-12 form-footer">
                        <p>Created on: {this.props.initialData.date_display}</p>
                    </div>
                    : null
                }

                {this.state.errors.general && 
                    <div class="col-12 text-error mt-3">{this.state.errors.general}</div>
                }

            </GenericForm>
        );
    }
}

const StockLogForm = withRouter(_StockLogForm);


function LogPartInlines(props) {

    return props.services.map((service, index) => {
        let options = props.stockOptions;
        if (service.name) {
            options = [
                ...props.stockOptions, 
                {label: service.category + ' - ' + service.name, 
                    value: service.name, category: service.category,
                    pk: service.pk
                }
            ];
        }

        let unitOptions = [];
        let usageLabel = 'Usage';
        if (TRACK_AREA.includes(service.category)) {
            unitOptions = AREA_UNITS;
            usageLabel += ' (Area)';
        } else if (TRACK_LENGTH.includes(service.category)) {
            unitOptions = LENGTH_UNITS;
            usageLabel += ' (Length)';
        } else if (TRACK_QTY.includes(service.category)) {
            unitOptions = QTY_UNITS;
            usageLabel += ' (Qty)';
        }

        let editable = service.isNew || false;

        return (
            <div className="form-row bordered dismissable" key={index + '_' + service.pk}>
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-9 col-12">
                            <FormAsyncSelectInput 
                                name={"service_item_" + index}
                                variant="fancy"
                                label="Stock item"
                                options={options}
                                defaultValue={service.name} 
                                onChangeCallback={(val) => props.onStockItemChange(val, index)}
                                error={props.errors[index].name}
                                required={true}
                                loadOptions={props.loadStockOptions}
                                isClearable={true}
                                placeholder="Search stock item..."
                                helpText={service.remaining && 'Remaining: ' + service.remaining}
                                readOnly={!editable}
                            />

                            {service.category &&
                                <>
                                <div className="row form-group-row">
                                    <div className="col-6">
                                        <FormInput 
                                            name={"service_usage_" + index}
                                            type="text" 
                                            placeholder={usageLabel}
                                            label={usageLabel}
                                            value={service.usage} 
                                            onChange={(e) => props.onChange(e, 'usage', index)}
                                            error={props.errors[index].usage}
                                            required={true}
                                            readOnly={!editable}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <FormSelectInput 
                                            name={"service_usage_unit_" + index}
                                            label={" "}
                                            defaultValue={service.usage_unit} 
                                            variant="fancy"
                                            options={unitOptions}
                                            onChangeCallback={(val) => props.onSelectChange(val, 'usage_unit', index)}
                                            error={props.errors[index].usage_unit}
                                            isClearable={true}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                <div className="row form-group-row">
                                    <div className="col-6">
                                        <FormInput 
                                            name={"service_wastage_" + index}
                                            type="text" 
                                            placeholder="Wastage"
                                            label="Wastage"
                                            value={service.wastage} 
                                            onChange={(e) => props.onChange(e, 'wastage', index)}
                                            error={props.errors[index].wastage}
                                            readOnly={!editable}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <FormSelectInput 
                                            name={"service_wastage_unit_" + index}
                                            label={" "}
                                            defaultValue={service.wastage_unit} 
                                            variant="fancy"
                                            options={unitOptions}
                                            onChangeCallback={(val) => props.onSelectChange(val, 'wastage_unit', index)}
                                            error={props.errors[index].wastage_unit}
                                            isClearable={true}
                                            readOnly={!editable}
                                        />
                                    </div>
                                </div>
                                </>
                            }
                        </div>
                    </div>                
                    {props.services.length === 1 || !editable ? null : <CloseButton onClick={() => props.removeService(index)} />}
                </div>
            </div>
        );
    });
}
