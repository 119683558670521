import React, {Component} from 'react';
import {Printer} from 'react-feather';
import {API} from '../api.js';
import {GenericPageHeader} from '../generics';
import {FormInput, FormSelectInput, toast, Table} from '../ui';


export default class ReportPage extends Component {
    render() {
        let api = new API('report');
        let permissions = this.props.permissions.report;

        if (!permissions.can_view) {
            return (
                <div className="main">
                <GenericPageHeader 
                    title={"Reports"} 
                    hideSubnav={true}
                    location={this.props.location}
                />

                <div className="content text-error">
                    <h3>You don't have permission to view this page.</h3>
                </div>
            </div>
            );
        }

        return (
            <div className="main">
                <GenericPageHeader 
                    title={"Reports"} 
                    hideSubnav={true}
                    location={this.props.location}
                />

                <div className="content">
                    <Report 
                        title="Invoice report" 
                        api={api}
                        instance={this.props.instance}
                        view='invoice'
                    />

                    <hr className="mt-3 mb-3" />

                    <Report 
                        title="Printing report" 
                        api={api}
                        instance={this.props.instance}
                        view='printing'
                    />
                </div>
            </div>
        );
    }
}


class Report extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fromDate: '',
            toDate: '',
            firm: '',
            data: {},
            firmOptions: ''
        };
    }

    componentDidMount() {
        if (this.props.view === 'invoice') {
            let api = new API('invoiceFirm');

            api.getList('instance=' + this.props.instance.id)
            .then((response) => {
                this.setState({firmOptions: response.data.results.map((val) => ({label: val.name, value: val.id}))});
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.view === 'invoice') {
            if (this.state.fromDate === prevState.fromDate && 
                this.state.toDate === prevState.toDate &&
                this.state.firm === prevState.firm) {
                return;
            }
        } else if (this.props.view === 'printing') {
            if (this.state.fromDate === prevState.fromDate && 
                this.state.toDate === prevState.toDate) {
                return;
            }
        }

        this.updateData();
    }

    updateData = () => {
        if (!this.state.fromDate || !this.state.toDate) {
            this.setState({data: {}});
            return;
        }

        if (new Date(this.state.fromDate) > new Date(this.state.toDate)) {
            this.setState({data: {}});
            return;
        }

        if (this.props.view === 'invoice' && !this.state.firm) {
            this.setState({data: {}});
            return;
        }

        let queryString = new URLSearchParams();

        queryString.set('instance', this.props.instance.id);
        queryString.set('view', this.props.view);
        queryString.set('from_date', this.state.fromDate);
        queryString.set('to_date', this.state.toDate);
        queryString.set('firm', this.state.firm);

        toast.showLoader();

        this.props.api.getList(queryString)
        .then((response) => {
            toast.hideLoader();

            let data = response.data;
            if (data.hasOwnProperty('error')) {
                toast.error(data.error);
            } else {
                this.setState({data: response.data});
            }
        })
        .catch((error) => {
            toast.hideLoader();
            if (error.response) {
                toast.error('Something went wrong. Contact administrator.');
            } else {
                toast.showConnectionError(this.updateData);
            }
        });
    }

    render() {
        return (
            <div className="table-container">
                <h3 className="table-container-header">{this.props.title}</h3>
                <div className="toolbar">
                    <div className="row">
                        <div className="col-sm-4 col-6">
                            <FormInput 
                                label="From"
                                placeholder="From"
                                type="date" 
                                onChange={(e) => this.setState({fromDate: e.target.value})}
                                value={this.state.fromDate}
                            />
                        </div>
                        <div className="col-sm-4 col-6">
                            <FormInput 
                                label="To"
                                placeholder="To"
                                type="date" 
                                onChange={(e) => this.setState({toDate: e.target.value})}
                                value={this.state.toDate}
                            />
                        </div>
                        <div className="col-sm-4">
                            {this.props.view === 'invoice' ? 
                                <FormSelectInput
                                    name="firm" 
                                    placeholder="Firm" 
                                    label="Firm"
                                    variant="fancy"
                                    options={this.state.firmOptions}
                                    onChangeCallback={(e) => this.setState({firm: e.value})}
                                    isLoading={this.state.firmOptions.length ? false : true}
                                    isClearable={true}
                                />
                                :
                                <FormInput 
                                    label="Branch"
                                    placeholder="Branch"
                                    type="text"
                                    disabled={true}
                                    value={this.props.instance.name}
                                />
                            }
                        </div>
                    </div>
                </div>

                {this.props.view === 'invoice' ?
                    <InvoiceReportTable data={this.state.data} instance={this.props.instance} />
                    :
                    <PrintingReportTable data={this.state.data} instance={this.props.instance} />
                }
            </div>
        );
    }
}


function InvoiceReportTable(props) {
    if (props.data && props.data.hasOwnProperty('results') && props.data.results.length) {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="table-outer">
                        <Table
                            instance={props.instance} 
                            data={props.data.results}
                            tableCols={['count', 'gross_bill', 'tax', 'discount', 'net_bill']}
                            tableTh={['Invoices generated', 'Bills (Gross)', 'Taxes', 'Discounts', 'Bills (Net)']}
                            tableThWidths={['150', '150', '100', '150', '150']}
                            tableColCls={['col-6', 'col-6', 'col-6', 'col-6', 'col-6']}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 text-center">
                <div className="well">
                    <h1>No data to show for selected dates and firm</h1>
                </div>
            </div>
        </div>
    );
}


function PrintingReportTable(props) {
    if (props.data && props.data.hasOwnProperty('results') && props.data.results.length) {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="table-outer">
                        <Table
                            instance={props.instance} 
                            data={props.data.results}
                            tableCols={['item', 'usage_area', 'wastage_area', 'total_area']}
                            tableTh={['Item', 'Usage (Area)', 'Wastage (Area)', 'Total (Area)']}
                            tableThWidths={['150', '150', '150', '150']}
                            tableColCls={['col-12', 'col-4', 'col-4', 'col-4']}
                        />

                        <div className="mt-1 mb-1 text-center">
                            <a href={props.data.pdf_url} className="btn outline-primary" target="_blank" rel="noopener noreferrer"><Printer /> Print Report</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="row">
            <div className="col-12 text-center">
                <div className="well">
                    <h1>No data to show for selected dates and branch</h1>
                </div>
            </div>
        </div>
    );
}