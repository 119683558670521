import React, {Component} from 'react';
import {Button, toast, Modal, FlyoutBase} from '../ui';
import {requestErrorMsg} from '../utils.js';


export default class GenericForm extends Component {
    /*
        Props:
            beforeSubmit: callback to run before submitting form.
            afterSubmit: callback to run after successful submission form.
            
    */
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            submitSuccess: false,
            deleting: false,
            deleteSuccess: false,
            showModal: false,
            addAnother: false,
            invalid: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createEntity = this.createEntity.bind(this);
        this.updateEntity = this.updateEntity.bind(this);
        this.deleteEntity = this.deleteEntity.bind(this);
        this.handleSubmissionError = this.handleSubmissionError.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleDeletionError = this.handleDeletionError.bind(this);
        this.handleAddAnotherClick = this.handleAddAnotherClick.bind(this);

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.submitting)
            return;

        if (this.props.isNew && !this.props.permissions.can_add) {
            return;
        }

        if (!this.props.isNew && !this.props.permissions.can_change) {
            return;
        }

        this.setState({submitting: true, submitSuccess: false, invalid: false});

        let formData = new FormData(e.target);
        let {data, isValid, dataType} = this.props.handleFormData(formData);

        if (dataType === 'form-data') {
            if (!isValid || (!data.get('instance') && this.props.isNew)) {
                if (!data.get('instance')) {
                    console.log("instance is required in form-data");
                }
                this.setState({invalid: true, submitting: false});
                return;
            }
        } else {

            if (!isValid || (!data.instance && this.props.isNew)) {
                if (!data.instance) {
                    console.log("instance is required");
                }
                this.setState({invalid: true, submitting: false});
                return;
            }
        }
        
        if (this.props.entityId)
            this.updateEntity(data);
        else
            this.createEntity(data);
    }

    handleChange(e) {
        if (this.state.invalid)
            this.setState({invalid: false});
    }

    handleAddAnotherClick(e) {
        this.setState({addAnother: true});
    }

    handleCancel = (e) => {
        this.setState(
            {unsaved: false}, 
            () => {
                if (this.props.afterCancel)
                    this.props.afterCancel();
            }
        );
    }

    createEntity(data) {
        this.props.api.createEntity(data, 'instance=' + this.props.instance.id)
        .then((response) => {
            this.setState({submitting: false, submitSuccess: true});
            this.props.afterSubmit(response, this.state.addAnother);
        })
        .catch((error) => {
            this.handleSubmissionError(error);
        });
    }

    updateEntity(data) {
        this.props.api.updateEntity(this.props.entityId, data, 'instance=' + this.props.instance.id)
        .then((response) => {
            /* 
                check for validation errors. 
                probably 200 response will contain validation errors.
                201 and 202 are for success crate/update
            */
            this.setState({submitting: false, submitSuccess: true});
            this.props.afterSubmit(response, this.state.addAnother);
        })
        .catch((error) => {
            this.handleSubmissionError(error);
        });
    }

    deleteEntity() {
        this.props.api.deleteEntity(this.props.entityId, 'instance=' + this.props.instance.id)
        .then((response) => {
            this.setState({deleting: false, deleteSuccess: true, showModal: false});
            this.props.afterDelete(response);
        })
        .catch((error) => {
            this.handleDeletionError(error);
        });
    }

    handleConfirmDelete(e) {
        this.setState({deleting: true, deleteSuccess: false});
        this.deleteEntity();
    }

    handleSubmissionError(error) {
        console.log(error);

        let {errorType, errorMsg} = requestErrorMsg(error);

        if (errorType === 400) {
            // bad request
            let errors = {};

            if (error.response) {
                if (this.props.setFullErrors) {
                    errors = error.response.data;
                } else {
                    for (let key in error.response.data) {
                        errors[key] = error.response.data[key][0];
                    }
                }
            }
            this.props.setErrors(errors);
            this.setState({submitting: false, submitSuccess: false, invalid: true});
        } else {
            toast.error(errorMsg);
            this.setState({submitting: false, submitSuccess: false});
        }

    }

    handleDeletionError(error) {
        console.log(error);

        let {errorMsg} = requestErrorMsg(error);

        toast.error(errorMsg);

        this.setState({deleting: false, deleteSuccess: false});
    }

    handleOpenModal(e) {
        this.setState({showModal: true});
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.submitSuccess !== prevState.submitSuccess) {
            if (this.state.submitSuccess && !this.state.submitting)
                toast.success("Saved successfully");
        }

        if (this.state.deleteSuccess !== prevState.deleteSuccess) {
            if (this.state.deleteSuccess && !this.state.deleting)
                toast.success("Deleted sucessfully");
        }

        if (this.state.submitting || this.state.deleting)
            toast.hideAll();
    }

    render() {
        let deleteBtn = null;
        if (!this.props.isNew && this.props.permissions.can_delete) {
            deleteBtn = <Button className="danger" type="button" onClick={this.handleOpenModal}>Delete</Button>;
        }

        let saveBtn = null;
        if ((this.props.isNew && this.props.permissions.can_add) || 
            (!this.props.isNew && this.props.permissions.can_change)) {
            saveBtn = <Button className="primary" type="submit" loading={this.state.submitting}>
                        {this.state.submitting ? 'Saving...' : 'Save'}
                      </Button>;
        }

        let saveAddBtn = this.props.extraButton || <Button className="default" type="reset" onClick={this.handleCancel}>Cancel</Button>;
        /*
        if ((this.props.isNew && this.props.permissions.can_add) ||
            (this.props.permissions.can_add && this.props.permissions.can_change)) {
            saveAddBtn = <Button className="outline-primary" type="submit" onClick={this.handleAddAnotherClick}>
                           Save and add another
                         </Button>;
        }
        */

        return (
            <div className="form-container">
                <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                    <div className="row">
                        {this.props.children}
                        <div className="col-12 form-btns">
                            <div className="form-group">
                                {
                                    this.state.invalid ? <div className="alert error">Form has errors</div> : null
                                }
                                {saveBtn}
                                {saveAddBtn}
                                {deleteBtn}
                            </div>
                        </div>
                    </div>
                </form>

                <Modal 
                   isOpen={this.state.showModal}
                   handleCloseModal={this.handleCloseModal}
                   title='Delete this?'
                   actionBtnClassName='danger'
                   actionBtnLabel={this.state.deleting ? 'Deleting...' : 'Delete'}
                   actionBtnLoading={this.state.deleting}
                   actionBtnOnClick={this.handleConfirmDelete}
                >
                    <p>Are you sure you want to <strong>permanently delete</strong> this object?</p>
                </Modal>
            </div>
        );
    }
}


export class GenericFlyoutForm extends Component {
    /*
        Props:
            beforeSubmit: callback to run before submitting form.
            afterSubmit: callback to run after successful submission form.
            handleFormData: callback to validate formData and return validated data.
            title: title for Flyout
            
    */
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            submitSuccess: false,
            deleting: false,
            deleteSuccess: false,
            showModal: false,
            addAnother: false,
            invalid: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createEntity = this.createEntity.bind(this);
        this.updateEntity = this.updateEntity.bind(this);
        this.deleteEntity = this.deleteEntity.bind(this);
        this.handleSubmissionError = this.handleSubmissionError.bind(this);
        this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
        this.handleDeletionError = this.handleDeletionError.bind(this);
        this.handleAddAnotherClick = this.handleAddAnotherClick.bind(this);

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

        this.formBtnRef = React.createRef();
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.submitting)
            return;

        if (this.props.isNew && !this.props.permissions.can_add) {
            return;
        }

        if (!this.props.isNew && !this.props.permissions.can_change) {
            return;
        }

        this.setState({submitting: true, submitSuccess: false, invalid: false});

        let formData = new FormData(e.target);
        let {data, isValid} = this.props.handleFormData(formData);

        if (!isValid || (!data.instance && this.props.isNew)) {
            this.setState({invalid: true, submitting: false});
            if (!data.instance) {
                console.log("instance is required");
            }
            return;
        }
        
        if (this.props.entityId)
            this.updateEntity(data);
        else
            this.createEntity(data);
    }

    handleChange(e) {
        if (this.state.invalid)
            this.setState({invalid: false});
    }

    handleAddAnotherClick(e) {
        this.setState({addAnother: true});
    }

    handleCancel = (e) => {
        /*this.setState(
            {unsaved: false, invalid: false}, 
            () => {
                if (this.props.afterCancel)
                    this.props.afterCancel();

                this.handleCloseFlyout();
            }
        );*/
        this.handleCloseFlyout();
    }

    handleCloseFlyout = () => {
        this.setState({
            invalid: false, submitting: false, submitSuccess: false,
            unsaved: false
        });

        this.props.handleCloseFlyout();
    }

    createEntity(data) {
        this.props.api.createEntity(data, 'instance=' + this.props.instance.id)
        .then((response) => {
            this.setState({submitting: false, submitSuccess: true});
            this.props.afterSubmit(response, this.state.addAnother);
        })
        .catch((error) => {
            this.handleSubmissionError(error);
        });
    }

    updateEntity(data) {
        this.props.api.updateEntity(this.props.entityId, data, 'instance=' + this.props.instance.id)
        .then((response) => {
            /* 
                check for validation errors. 
                probably 200 response will contain validation errors.
                201 and 202 are for success crate/update
            */
            this.setState({submitting: false, submitSuccess: true});
            this.props.afterSubmit(response, this.state.addAnother);
        })
        .catch((error) => {
            this.handleSubmissionError(error);
        });
    }

    deleteEntity() {
        this.props.api.deleteEntity(this.props.entityId, 'instance=' + this.props.instance.id)
        .then((response) => {
            this.setState({deleting: false, deleteSuccess: true, showModal: false});
            this.props.afterDelete(response);
        })
        .catch((error) => {
            this.handleDeletionError(error);
        });
    }

    handleConfirmDelete(e) {
        this.setState({deleting: true, deleteSuccess: false});
        this.deleteEntity();
    }

    handleSubmissionError(error) {
        console.log(error);

        let {errorType, errorMsg} = requestErrorMsg(error);

        if (errorType === 400) {
            // bad request
            let errors = {};

            if (error.response) {
                for (let key in error.response.data) {
                    errors[key] = error.response.data[key][0];
                }
            }
            this.props.setErrors(errors);
            this.setState({submitting: false, submitSuccess: false, invalid: true});
        } else {
            toast.error(errorMsg);
            this.setState({submitting: false, submitSuccess: false});
        }

    }

    handleDeletionError(error) {
        console.log(error);

        let {errorMsg} = requestErrorMsg(error);

        toast.error(errorMsg);

        this.setState({deleting: false, deleteSuccess: false});
    }

    handleOpenModal(e) {
        this.setState({showModal: true});
    }

    handleCloseModal() {
        this.setState({showModal: false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.submitSuccess !== prevState.submitSuccess) {
            if (this.state.submitSuccess && !this.state.submitting)
                toast.success("Saved successfully");
        }

        if (this.state.deleteSuccess !== prevState.deleteSuccess) {
            if (this.state.deleteSuccess && !this.state.deleting)
                toast.success("Deleted sucessfully");
        }

        if (this.state.submitting || this.state.deleting)
            toast.hideAll();
    }

    submitForm = () => {
        this.formBtnRef.current.click();
    }

    render() {
        let deleteBtn = null;
        if (!this.props.isNew && this.props.permissions.can_delete) {
            deleteBtn = <Button className="danger" type="button" onClick={this.handleOpenModal}>Delete</Button>;
        }

        let saveBtn = null;
        if ((this.props.isNew && this.props.permissions.can_add) || 
            (!this.props.isNew && this.props.permissions.can_change)) {
            saveBtn = <Button 
                        className="primary" 
                        type="button" 
                        loading={this.state.submitting}
                        onClick={this.submitForm}
                        >
                        {this.state.submitting ? 'Saving...' : 'Save'}
                      </Button>;
        }

        let saveAddBtn = this.props.extraButton || <Button className="default" type="reset" onClick={this.handleCancel}>Cancel</Button>;
        /*
        if ((this.props.isNew && this.props.permissions.can_add) ||
            (this.props.permissions.can_add && this.props.permissions.can_change)) {
            saveAddBtn = <Button className="outline-primary" type="submit" onClick={this.handleAddAnotherClick}>
                           Save and add another
                         </Button>;
        }
        */
        return (
            <FlyoutBase
                title={this.props.title}
                isOpen={this.props.isOpen}
                handleCloseFlyout={this.handleCloseFlyout}
                blocking={true}
            >
                <div className="flyout-body">
                    <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
                        <div className="row">
                            {this.props.children}
                        </div>

                        <div className="d-none">
                            <button type="submit" ref={this.formBtnRef}>Save</button>
                        </div>
                    </form>
                </div>
                <div className="flyout-footer">
                    {
                        this.state.invalid ? <div className="alert error">Form has errors</div> : null
                    }
                    {saveBtn}
                    {saveAddBtn}
                    {deleteBtn}
                </div>

                <Modal 
                   isOpen={this.state.showModal}
                   handleCloseModal={this.handleCloseModal}
                   title='Delete this?'
                   actionBtnClassName='danger'
                   actionBtnLabel={this.state.deleting ? 'Deleting...' : 'Delete'}
                   actionBtnLoading={this.state.deleting}
                   actionBtnOnClick={this.handleConfirmDelete}
                >
                    <p>Are you sure you want to <strong>permanently delete</strong> this object?</p>
                </Modal>
            </FlyoutBase>
        );
    }
}
