import React from 'react';
import {matchPath} from 'react-router-dom';
import {Plus, RotateCw, ArrowLeft} from 'react-feather';
import {SubNav} from '../ui';
import {ReverseLink} from '../utils.js';


export default function GenericPageHeader(props) {
    /* 
        Generic page header component.

        Props:
            title:      Base title of the page.
            edit_url:   URL pattern for edit page.
            add_url:    URL pattern for add page.

    */
    let title = props.title;
    let path = props.location.pathname;
    let backBtn = null;

    let list_filters = new URLSearchParams(props.location.search).get('_list_filters') || '';

    if (matchPath(path, props.edit_url)) {
        title = title + ' / Edit';
        backBtn = <ReverseLink to={props.list_url + list_filters} args={{instance: props.instance.slug}}><ArrowLeft /> View All</ReverseLink>
    }
    else if (matchPath(path, props.add_url)) {
        title = title + ' / Add New';
        backBtn = <ReverseLink to={props.list_url + list_filters} args={{instance: props.instance.slug}}><ArrowLeft /> View All</ReverseLink>
    }

    /* :TODO: Move SITE_NAME to a goabl variable in App.js file */
    const SITE_NAME = 'Kawasaki Advertisers';
    document.title = props.title + ' | '+ SITE_NAME;


    return (
        <div className="header">
            <div className="row">
                <div className="col-12 col-md-5">
                    <h3>{title}</h3>
                </div>
                <div className="col-12 col-md-7">
                    {!props.hideSubnav && 
                        <SubNav>
                            {backBtn}
                            {props.permissions.can_add ? 
                                <ReverseLink className="active" to={props.add_url} args={{instance: props.instance.slug}}><Plus /> Add New</ReverseLink>
                                : null
                            }
                            <button className="btn" onClick={props.onRefresh}><RotateCw /> Refresh</button>
                        </SubNav>
                    }
                </div>
            </div>
        </div>
    );
}