import React, {Component} from 'react';
import ReactModal from 'react-modal';
import {X} from 'react-feather';
import {Button} from './buttons.js';


export default class Modal extends Component {
    componentDidUpdate(prevProps, prevState) {
        if (this.props.isOpen && !prevProps.isOpen) {
            let documentWidth = document.documentElement.clientWidth;
            let windowWidth = window.innerWidth;
            let scrollBarWidth = windowWidth - documentWidth;

            document.body.style.cssText = "overflow: hidden; padding-right: " + scrollBarWidth + "px;";
            document.getElementsByClassName('top-nav')[0].style.cssText = "padding-right: " + scrollBarWidth + "px;";
        }

        if (!this.props.isOpen && prevProps.isOpen) {
            document.body.style.cssText = "";
            document.getElementsByClassName('top-nav')[0].style.cssText = "";
        }
    }
    
    render() {
        return (
                <ReactModal 
                    contentLabel={this.props.title}
                    ariaHideApp={false}
                    overlayClassName='modal-overlay'
                    bodyOpenClassName='modal-open'
                    className={this.props.vAlign === 'top' ? 'modal-dialog modal-valign-top' : 'modal-dialog'}
                    isOpen={this.props.isOpen}
                    onRequestClose={this.props.handleCloseModal}
                    closeTimeoutMS={200}
                    shouldCloseOnOverlayClick={!this.props.blocking}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            {this.props.title}
                            <button className="modal-close" onClick={this.props.handleCloseModal}><X /></button>
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        <div className="modal-footer">
                            <Button className="default" onClick={this.props.handleCloseModal}>{this.props.cancelBtnLabel || 'Cancel'}</Button>
                            {this.props.actionBtnLabel ? 
                              <Button 
                                  className={this.props.actionBtnClassName}
                                  onClick={this.props.actionBtnOnClick}
                                  loading={this.props.actionBtnLoading}
                              >
                                  {this.props.actionBtnLabel}
                              </Button>
                              : null
                            }
                        </div>
                    </div>
            </ReactModal>
        );
    }
}