import React, {Component, Children} from 'react';
import {CSSTransitionGroup} from 'react-transition-group';
import {Link} from 'react-router-dom';
import {URLS} from '../urlconf.js';
import {Menu, X} from 'react-feather';
import {ReverseNavLink} from '../utils.js';


function SubNav(props)  {
    return (
        <div className={'nav sub-nav'}>
            <ul>
                {Children.map(props.children, (child) => child ? <li>{child}</li> : null)}
            </ul>
        </div>
    );
}


class SideNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.instance !== prevProps.instance && this.state.open) {
            this.setState({open: false});
            document.body.classList.remove('nav-open');
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('nav-open');
    }

    toggleNav(e) {
        this.setState({open: !this.state.open});
        document.body.classList.toggle('nav-open');
    }

    handleLinkClick(e) {
        if (this.state.open) {
            this.toggleNav();
        }
    }

    render() {
        let navlinks = this.props.navlinks || [];

        return (
            <div>
                <CSSTransitionGroup
                    transitionName="overlay"
                    transitionEnterTimeout={100}
                    transitionLeaveTimeout={150}
                >
                    {(this.state.open && <div className="overlay" key="overlay" onClick={this.handleLinkClick}></div>) || null}
                </CSSTransitionGroup>
                <div className="nav top-nav">
                    <Link to={"/" + this.props.instance + "/"} className="logo">
                        <img src={process.env.PUBLIC_URL + "/img/logo3b.png"}  alt="Kawasaki Advertisers" className="d-none d-sm-block" />
                        <img src={process.env.PUBLIC_URL + "/img/logo3b-icon.png"}  alt="Kawasaki" className="d-block d-sm-none" />
                    </Link> {' '}

                    <button className="toggle-nav d-md-none" onClick={this.toggleNav}>
                        {this.state.open ? <X /> : <Menu />}
                    </button>
                    <ul className="d-none d-md-block">
                        {navlinks.map((value, index) => <li key={index}>{value}</li>)}
                    </ul>
                </div>
                <div className={this.state.open ? "nav side-nav open" : "nav side-nav"} id={this.props.id}>
                    <ul>
                        <li><ReverseNavLink to={URLS.home} exact onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Home</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.challan_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Challan</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.order_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Orders</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.invoice_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Invoice</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.quotation_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Quotation</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.invoiceFirm_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Firm</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.stockIn_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Stock In</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.stockOut_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Stock Out</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.tool_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Tools</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.task_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Tasks</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.report_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Reports</ReverseNavLink></li>
                        <li><ReverseNavLink to={URLS.user_all} onClick={this.handleLinkClick} args={{instance: this.props.instance}}>Users</ReverseNavLink></li>
                        {navlinks.map((value, index) => <li key={index} className="d-md-none">{value}</li>)}
                    </ul>
                </div>
            </div>
        );
    }
}


export {SideNav, SubNav};