import React, { Component } from 'react';
import {Download, X} from 'react-feather';


export default class AddToHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            canInstall: false
        };
    }

    componentDidMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();

            this.deferredPrompt = e;
            this.setState({canInstall: true});
        });

    }

    onInstallClick = (e) => {
        // Show the prompt
        this.deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                // install successfull; hide button
                this.setState({canInstall: false});
            } else {
                // user dismissed prompt
            }
            this.deferredPrompt = null;
        });
    }

    hideBanner = (e) => {
        this.setState({canInstall: false})
    }

    render() {
        if (!this.state.canInstall) 
            return null;

        return (
            <div className="add-to-home">
                Add to Home Screen 
                {' '}
                <button 
                    type="button" 
                    className="btn outline-primary btn-sm"
                    onClick={this.onInstallClick}
                >
                    <Download /> Install
                </button>

                <button 
                    type="button"
                    className="modal-close"
                    onClick={this.hideBanner}
                >
                    <X/>
                </button>
            </div>
        );
    }
}