import React, {Component} from 'react';
import {Loader, Button, Flyout} from '../ui';
import {Info} from 'react-feather';

export class ObjectInfoFlyout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            hasError: false,
            errorType: null,
            error: '',
            object: null,
            prevObject: null,
            objectInfoShown: false
        }
    }

    showObjectInfo = () => {
        let isLoading = false;

        if (this.state.hasError || this.props.object !== this.state.prevObject) {
            isLoading = true;
        }

        this.setState({
            objectInfoShown: true, isLoading: isLoading, hasError: false,
            prevObject: this.props.object
        }, this.fetchObjectInfo);
    }

    hideObjectInfo = () => {
        this.setState({objectInfoShown: false});
    }

    fetchObjectInfo = () => {
        if (!this.state.isLoading)
            return;

        let objectIdTokens = this.props.object.split('/');
        let objectId = objectIdTokens[objectIdTokens.length - 2];

        this.props.api.getOne(objectId, this.props.queryString)
        .then((response) => {
            this.setState({isLoading: false, object: response.data, hasError: false, error: ''});
        })
        .catch((error) => {
            let errorType = 'CONNECTION_ERROR';
            let errorMsg = "Something went wrong. Please check your internet connection.";

            if (error.response)
                errorType = error.response.status;

            if (errorType === 404)
                errorMsg = "This object doesn't exist or it has been deleted.";
            if (errorType === 403)
                errorMsg = "You don't have permission to view this object.";
                
            this.setState({isLoading: false, hasError: true, errorType: errorType, error: errorMsg});
        });
    }

    render() {
        let body;

        if (this.state.isLoading) {
            body = <Loader />;
        } else if (this.state.hasError) {
            body = (<div className="text-error">
                    {this.state.error}
                </div>);
        } else {
            if (this.state.object) {
                body = this.props.render(this.state.object);
            }
        }


        return (
            <>

            {this.props.object ? 
                <div className="col-12 col-sm-3 col-md-6">
                    <div className="form-group">
                        <label className="d-none d-sm-block">&nbsp;</label>
                        <Button className="default" onClick={this.showObjectInfo}>
                            <Info /> Details
                        </Button>
                    </div>
                </div>
                : null
            }

            <Flyout 
               isOpen={this.state.objectInfoShown}
               handleCloseFlyout={this.hideObjectInfo}
               title={this.props.title}
               cancelBtnLabel='Close'
            >
                {body}
            </Flyout>

            </>
        );
    }
}

