import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm} from '../generics';
import {FormInput, FormCheckInput, Button} from '../ui';
import {reverse} from '../utils.js';


export default class UserBasePage extends Component {

    tableCheckCallback = (checkedIds) => {
        
    }

    render() {
        let api = new API('user');
        let permissions = this.props.permissions.user;

        return (
            <GenericBasePage 
                title="Users"
                api={api}
                instance={this.props.instance}
                list_url={URLS.user_all}
                add_url={URLS.user_add}
                edit_url={URLS.user_edit}
                tableRowURI={URLS.user_edit}
                tableCols={['username', 'branches', 'name', 'email', 'is_superuser']}
                tableTh={['Userame', 'Branches', 'Name', 'Email', 'Super User']}
                tableColCls={['col-12', 'col-4', 'col-4', 'col-4',]}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <UserForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                editForm={(initialData) => <UserForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}



class _UserForm extends Component {
    constructor(props) {
        super(props);

        let initialData = props.initialData || {};

        this.state = {
            data: {
                instance: initialData.instance || this.props.instance.id,
                first_name: initialData.first_name || '',
                last_name: initialData.last_name || '',
                username: initialData.username || '',
                email: initialData.email || '',
                is_superuser: initialData.is_superuser || false,
                password: '',
                password2: '',
                date_joined: initialData.date_joined,
                instances: initialData.instances || []
            },
            perms: initialData.permissions || [],
            errors: {
                first_name: '',
                last_name: '',
                username: '',
                email: '',
                is_superuser: '',
                password2: '',
                password: '',
                instances: ''
            },

            editingPassword: initialData.username ? false : true
        };

        this.handleFormData = this.handleFormData.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.afterDelete = this.afterDelete.bind(this);
        this.setErrors = this.setErrors.bind(this);
    }

    onUsernameChange = (e) => {
        const value = e.target.value;
        let error = '';
        let regex = RegExp('^[a-zA-Z0-9_.]+$');
        if (!regex.test(value))
            error = "Only alphabet, numbers, underscore (_) and dot (.) allowed";

        this.setState((state) => ({
            data: {...state.data, username: value},
            errors: {...state.errors, username: error}
            })
        );
    }

    onPasswordChange = (e) => {
        const value = e.target.value;
        let error = '';
        if (this.state.data.password2.length && this.state.data.password2 !== value)
            error = "Passwords don't match";

        this.setState((state) => ({
            data: {...state.data, password: value},
            errors: {...state.errors, password2: error}
            })
        );
    }

    onPassword2Change = (e) => {
        const value = e.target.value;
        let error = '';
        if (this.state.data.password !== value)
            error = "Passwords don't match";

        this.setState((state) => ({
            data: {...state.data, password2: value},
            errors: {...state.errors, password2: error}
            })
        );
    }

    handleFormData(formData) {
        let data = {
            instance: this.state.data.instance,
            first_name: formData.get('first_name').trim(),
            last_name: formData.get('last_name').trim(),
            username: formData.get('username').trim(),
            email: formData.get('email').trim(),
            is_superuser: (formData.get('is_superuser') && true) || false,
            instances: formData.getAll('instances'),
            permissions: [...this.state.perms]
        }

        let required_fields = ['username', 'instances'];

        if (this.state.editingPassword) {
            data['password'] = this.state.data.password;
            data['password2'] = this.state.data.password2;
            required_fields.push('password', 'password2');
        }

        let isValid = true;

        let errors = {...this.state.errors};

        let field = '';
        for (let i = 0; i < required_fields.length; i++) {
            field = required_fields[i];

            if (!data[field].length) {
                isValid = false;
                errors[field] = 'This field is required';
            } else {
                errors[field] = '';
            }
        }

        if (data.password !== data.password2) {
            errors.password2 = "Passwords don't match";
            isValid = false;
        }

        let regex = RegExp('^[a-zA-Z0-9_.]+$');
        if (!regex.test(data.username)) {
            isValid = false;
            errors.username = "Only alphabet, numbers, underscore (_) and dot (.) allowed";
        }

        this.setState({errors: errors});

        return {data: data, isValid: isValid};
    }

    setErrors(errors) {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit(response, addAnother) {
        let newPath = reverse(URLS.user_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname)
            this.props.history.push(newPath);
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.user_add);
    }

    afterDelete(response) {
        this.props.history.push(reverse(URLS.user_all, {instance: this.props.instance.slug}));
    }

    editPassword = (e) => {
        this.setState({editingPassword: true});
    }

    onSuperuserChange = (e) => {
        let val = e.target.checked;
        this.setState((state) => ({data: {...state.data, is_superuser: val}}));
    }

    onPermChange = (e) => {
        let name = e.target.name;
        let val = e.target.checked;


        this.setState((state) => {
            let perms = [...state.perms];
            if (!val) {
                perms = perms.filter((item) => item !== name);
            } else if (val && perms.indexOf(name) < 0) {
                perms.push(name);
            }

            return {perms: perms, data: {...state.data, is_superuser: state.data.is_superuser && val}};
        });

    }

    permIsChecked = (name) => {
        return this.state.perms.indexOf(name) > -1 || this.state.data.is_superuser;
    }

    render() {
        return (
            <GenericForm 
                isNew={this.props.initialData ? false : true}
                permissions={this.props.permissions}
                instance={this.props.instance}
                entityId={this.props.initialData ? this.props.initialData.id : null}
                api={this.props.api}
                handleFormData={this.handleFormData}
                afterSubmit={this.afterSubmit}
                afterDelete={this.afterDelete}
                afterCancel={this.afterDelete}
                setErrors={this.setErrors}
            >
                <div className="col-md-6">
                    <FormInput type="text" name="username" placeholder="Username" 
                        label="Username"
                        value={this.state.data.username}
                        error={this.state.errors.username}
                        required={true}
                        onChange={this.onUsernameChange}
                    />

                    {this.state.editingPassword ? 
                        <div className="row">
                            <div className="col-md-6">
                                <FormInput type="password" name="password" placeholder="Password" 
                                    label="Password"
                                    value={this.state.data.password}
                                    error={this.state.errors.password}
                                    required={true}
                                    onChange={this.onPasswordChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput type="password" name="password2" placeholder="Confirm password" 
                                    label="Confirm password"
                                    value={this.state.data.password2}
                                    error={this.state.errors.password2}
                                    required={true}
                                    onChange={this.onPassword2Change}
                                />
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-6 col-7">
                                <FormInput type="password" label="Password" value="********" readOnly={true} />
                            </div>
                            <div className="col-md-6 col-5">
                                <label>&nbsp;</label>
                                <Button className="default" onClick={this.editPassword}>Change</Button>
                            </div>
                        </div>
                    }

                    <FormCheckInput 
                        name="instances" 
                        label="Branch" 
                        variant="inline"
                        defaultValue={this.state.data.instances}
                        options={[
                            {label: 'Shimla', value: 1},
                            {label: 'Rampur', value: 2},
                        ]}
                        required={true}
                        error={this.state.errors.instances}
                    />

                    <div className="row">
                        <div className="col-md-6">
                            <FormInput type="text" name="first_name" placeholder="First name" 
                                label="First name"
                                defaultValue={this.state.data.first_name}
                                error={this.state.errors.first_name}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormInput type="text" name="last_name" placeholder="Last name" 
                                label="Last name"
                                defaultValue={this.state.data.last_name}
                                error={this.state.errors.lasst_name}
                            />
                        </div>
                    </div>

                    <FormInput type="text" name="email" placeholder="user@example.com" 
                        label="Email"
                        defaultValue={this.state.data.email}
                        error={this.state.errors.email}
                    />

                </div>

                <div className="col-12">
                    <h3 className="form-section-header">Permissions</h3>

                    <FormCheckInput 
                        name="is_superuser" 
                        label="Super User (will get all permissions)" 
                        checked={this.state.data.is_superuser}
                        onChange={this.onSuperuserChange}
                    />

                    <FormCheckInput name="order" label="Challan, Order" 
                        checked={this.permIsChecked('order')}
                        onChange={this.onPermChange}
                    />

                    <FormCheckInput name="invoice" label="Invoice, Quotation, Firm" 
                        checked={this.permIsChecked('invoice')}
                        onChange={this.onPermChange}
                    />

                    <FormCheckInput name="stockin" label="Stock in" 
                        checked={this.permIsChecked('stockin')}
                        onChange={this.onPermChange}
                    />

                    <FormCheckInput name="stockout" label="Stock out" 
                        checked={this.permIsChecked('stockout')}
                        onChange={this.onPermChange}
                    />

                    <FormCheckInput name="tool" label="Tools" 
                        checked={this.permIsChecked('tool')}
                        onChange={this.onPermChange}
                    />

                    <FormCheckInput name="task_view" label="Tasks (View)" 
                        checked={this.permIsChecked('task_view')}
                        onChange={this.onPermChange}
                    />

                    <FormCheckInput name="task_add" label="Tasks (Add/Delete)" 
                        checked={this.permIsChecked('task_add')}
                        onChange={this.onPermChange}
                    />

                    <FormCheckInput name="report" label="Reports" 
                        checked={this.permIsChecked('report')}
                        onChange={this.onPermChange}
                    />
                </div>

                {this.props.initialData ?
                    <div className="col-12 form-footer">
                        <p>Created on: {this.props.initialData.date_joined}</p>
                    </div>
                    : null
                }
            </GenericForm>
        );
    }
}

const UserForm = withRouter(_UserForm);
