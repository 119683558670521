import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm} from '../generics';
import {FormInput, FormCheckInput, FormTextArea} from '../ui';
import {ReverseLink, reverse} from '../utils.js';


export default class ChallanBasePage extends Component {
    constructor(props) {
        super(props);

        this.filters = [
            {
                label: 'Status', name: 'trashed', type: 'checkbox', 
                options: [
                    {label: 'Trashed', value: 'True'}, 
                ]
            },
            {
                label: 'Sorting', name: 'o', type: 'radio',
                options: [
                    {label: 'Newest first', value: '-created_on', default: true},
                    {label: 'Oldest first', value: 'created_on'},
                ]
            }
        ];
    }

    tableCheckCallback = (checkedIds) => {
        
    }

    render() {
        let api = new API('challan');
        let permissions = this.props.permissions.challan;

        return (
            <GenericBasePage 
                title="Challans"
                api={api}
                instance={this.props.instance}
                list_url={URLS.challan_all}
                add_url={URLS.challan_add}
                edit_url={URLS.challan_edit}
                listFilters={this.filters}
                tableRowURI={URLS.challan_edit}
                tableCols={['name', 'qty', 'designed', 'approved', 'trashed', 'created_on']}
                tableTh={['Name', 'Qty', 'Designed', 'Approved', 'Trashed', 'Date']}
                tableColCls={['col-12', 'col-3', 'col-3', 'col-3', 'col-3', 'col-12']}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <ChallanForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                editForm={(initialData) => <ChallanForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}


/*function BookTableRowActions(props) {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        alert("show file ID: " + this.props.rowId);
    }

    render() {
        return (
            <Button className='link' onClick={this.handleClick}><File /> View PDF</Button>
        );
    }
    return (
        <ReverseLink to={URLS.challan_edit} args={{id: props.rowId, instance: props.instance.slug}}><File /> PDF</ReverseLink>
    );
}
*/

//const BookTableRowActions = withRouter(_BookTableRowActions);


class _ChallanForm extends Component {
    constructor(props) {
        super(props);

        let initialData = props.initialData || {};

        this.state = {
            data: {
                instance: initialData.instance || this.props.instance.id,
                name: initialData.name || '',
                description: initialData.description || '',
                qty: initialData.qty || '',
                designed: initialData.designed || false,
                approved: initialData.approved || false,
                trashed: initialData.trashed || false,
                trash_reason: initialData.trash_reason || ''
            },
            errors: {
                name: '',
                description: '',
                qty: '',
                designed: '',
                approved: '',
                trashed: '',
                trash_reason: ''
            }
        };

        this.handleFormData = this.handleFormData.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.afterDelete = this.afterDelete.bind(this);
        this.setErrors = this.setErrors.bind(this);
    }

    handleFormData(formData) {
        let data = {
            instance: this.state.data.instance,
            name: formData.get('name').trim(),
            description: formData.get('description'),
            qty: formData.get('qty') || null,
            designed: formData.get('designed') || false,
            approved: formData.get('approved') || false,
            trashed: formData.get('trashed') || false,
            trash_reason: formData.get('trash_reason') || ''
        }

        let required_fields = ['name'];

        let isValid = true;

        let errors = {...this.state.errors};

        let field = '';
        for (let i = 0; i < required_fields.length; i++) {
            field = required_fields[i];

            if (!data[field].length) {
                isValid = false;
                errors[field] = 'This field is required';
            } else {
                errors[field] = '';
            }
        }

        this.setState({errors: errors});

        return {data: data, isValid: isValid};
    }

    setErrors(errors) {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit(response, addAnother) {
        let newPath = reverse(URLS.challan_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname)
            this.props.history.push(newPath);
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.challan_add);
    }

    afterDelete(response) {
        this.props.history.push(reverse(URLS.challan_all, {instance: this.props.instance.slug}));
    }


    handleTrashedCheck = () => {
        let data = {...this.state.data};
        data.trashed = !this.state.data.trashed;
        this.setState({data: data});
    }

    render() {
        return (
            <GenericForm 
                isNew={this.props.initialData ? false : true}
                permissions={this.props.permissions}
                instance={this.props.instance}
                entityId={this.props.initialData ? this.props.initialData.id : null}
                api={this.props.api}
                handleFormData={this.handleFormData}
                afterSubmit={this.afterSubmit}
                afterDelete={this.afterDelete}
                afterCancel={this.afterDelete}
                setErrors={this.setErrors}
            >
                <div className="col-md-6">
                    <FormInput type="text" name="name" placeholder="Name" 
                        label="Name"
                        defaultValue={this.state.data.name}
                        error={this.state.errors.name}
                        required={true}
                    />

                    <FormTextArea name="description" placeholder="Description"
                        rows="5"
                        label="Description"
                        defaultValue={this.state.data.description}
                        error={this.state.errors.description}
                    />

                    <FormInput type="number" name="qty" placeholder="Qty" 
                        min="0"
                        label="Qty"
                        defaultValue={this.state.data.qty}
                        error={this.state.errors.qty}
                    />

                    <FormCheckInput name="designed" label="Designed" 
                        defaultChecked={this.state.data.designed}
                    />

                    <FormCheckInput name="approved" label="Approved"
                        defaultChecked={this.state.data.approved}
                    />

                    <FormCheckInput 
                        name="trashed" label="Trashed"
                        checked={this.state.data.trashed}
                        onChange={this.handleTrashedCheck}
                    />

                    {this.state.data.trashed ? 
                        <FormTextArea 
                            name="trash_reason" placeholder="Trash reason"
                            label="Trash reason (Optional)"
                            defaultValue={this.state.data.trash_reason}
                            error={this.state.errors.trash_reason}
                        />
                        : null
                    }
                </div>
                {this.props.initialData ?
                    <div className="col-12 form-footer">
                        <p>Created on: {this.props.initialData.created_on}</p>
                        <p>Order:{' '} 
                            {this.props.initialData.order_filename ?
                                <ReverseLink 
                                    to={URLS.order_edit} 
                                    args={{id: this.props.initialData.order_id, instance: this.props.instance.slug}}
                                >
                                    {this.props.initialData.order_filename}
                                </ReverseLink>
                            : 'None' }
                        </p>
                    </div>
                    : null
                }
            </GenericForm>
        );
    }
}

const ChallanForm = withRouter(_ChallanForm);
