import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {reverse} from '../utils.js';
import {GenericBasePage, GenericForm, ObjectInfoFlyout} from '../generics';
import {FormInput, FormTextArea, FormAsyncSelectInput, Typeahead,
    CloseButton, Button, FormRadioInput, Modal, toast} from '../ui';
import {Plus, Printer, Search} from 'react-feather';


export class QuotationPage extends Component {
    render() {
        return <InvoiceBasePage {...this.props} quotation={true} />
    }
}



export default class InvoiceBasePage extends Component {
    constructor(props) {
        super(props);

        this.filters = [
            {
                label: 'Firm', name: 'firm', type: 'checkbox', 
                options: []
            },
            {
                label: 'Sorting', name: 'o', type: 'radio',
                options: [
                    {label: 'Newest first', value: '-date', default: true},
                    {label: 'Oldest first', value: 'date'},
                ]
            }
        ];
    }

    componentDidMount() {
        let api = new API('invoiceFirm');

        api.getList('instance=' + this.props.instance.id)
        .then((response) => {
            this.filters[0] = {
                label: 'Firm', name: 'firm', type: 'checkbox', 
                options: response.data.results.map((val) => ({label: val.name, value: val.id}) )
            };

            this.setState({update: true});
        });

    }

    tableCheckCallback = (checkedIds) => {
        
    }

    render() {
        let api = new API('invoice');
        let permissions = this.props.permissions.invoice;

        if (this.props.quotation) {
            api = new API('quotation');
            permissions = this.props.permissions.quotation;
        }

        return (
            <GenericBasePage 
                title={this.props.quotation ? "Quotation" : "Invoice"}
                api={api}
                instance={this.props.instance}
                list_url={this.props.quotation ? URLS.quotation_all : URLS.invoice_all}
                add_url={this.props.quotation ? URLS.quotation_add : URLS.invoice_add}
                edit_url={this.props.quotation ? URLS.quotation_edit : URLS.invoice_edit}
                listFilters={this.filters}
                tableRowURI={this.props.quotation ? URLS.quotation_edit : URLS.invoice_edit}
                tableCols={['invoice_id', 'client_name', 'firm_name', 'date']}
                tableTh={[this.props.quotation ? 'Quotation ID' : 'Invoice ID', 'Client', 'Firm', 'Date']}
                tableThWidths={['150', '', '150', '175', '100']}
                tableColCls={['col-12', 'col-6', 'col-6', 'col-12']}
                tableRowActions={(row) => <InvoiceTableRowActions row={row} />}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <InvoiceForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} quotation={this.props.quotation} />}
                editForm={(initialData) => <InvoiceForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} quotation={this.props.quotation} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}


function InvoiceTableRowActions(props) {
    /*constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        alert("show file ID: " + this.props.rowId);
    }

    render() {
        return (
            <Button className='link' onClick={this.handleClick}><File /> View PDF</Button>
        );
    }*/
    return (
        <a href={props.row.pdf_url} className="btn link2" target="_blank" rel="noopener noreferrer"><Printer /> Print</a>
        //<Button className="link2"><Printer /> Print</Button>
    );
}


function getServiceTotal(qty, rate, tax_gst, tax_igst) {
    let amount = 0;
    let tax = 0;

    if (!isNaN(qty) && !isNaN(rate))
        amount = qty * rate;

    if (tax_gst) {
        if (isNaN(tax_gst))
            amount = 0;
        else {
            tax = amount * tax_gst / 100;
            //amount = amount + (amount * tax_gst / 100);
        }
    }

    if (tax_igst) {
        if (isNaN(tax_igst)) {
            tax = 0;
            amount = 0;
        }
        else {
            tax = tax + (amount * tax_igst / 100);
            //amount = amount + (amount * tax_igst / 100);
        }
    }

    return {amount: amount, tax: tax, total: amount + tax};
}


class _InvoiceForm extends Component {
    constructor(props) {
        super(props);

        let initialData = {
            instance: props.instance.id,

            firm: '',

            client_name: '',
            client_address: '',
            client_phone: '',
            client_gstin: '',
            po_num: '',
            po_date: '',

            disc_pct: '',
            ...props.initialData
        };

        if (!initialData.services)
            initialData.services = JSON.stringify([
                {description: '', qty: '', rate: '', 'tax_gst': '', 'tax_igst': '', hsn: ''}
            ]);

        this.state = {
            instance: initialData.instance,
            firm: initialData.firm,

            client_name: initialData.client_name,
            client_address: initialData.client_address,
            client_phone: initialData.client_phone,
            client_gstin: initialData.client_gstin,
            po_num: initialData.po_num,
            po_date: initialData.po_date || '',

            disc_pct: initialData.disc_pct || '',

            quotation_type: initialData.quotation_type || '',

            errors: {
                firm: '',
                client_name: '',
                disc_pct: ''
            },
            services: JSON.parse(initialData.services),

            firmFlyoutIsOpen: false,
            firmFlyoutIsLoading: false,
            editingFirm: false,
            firmOptionsLoading: true,
        };

        this.state.serviceErrors = this.state.services.map(
            (service) => ({description: '', qty: '', rate: '', 'tax_gst': '', 'tax_igst': '', hsn: ''})
        );

        if (initialData.firm.length > 0) {
            this.firmOptions = [{label: initialData.firm_name, value: initialData.firm}];
            this.state.firmOptionsLoading = false;
        } else {
            this.firmOptions = [];
            this.state.firmOptionsLoading = true;
        }

        this.clientApi = new API('invoiceClient');
        this.firmApi = new API('invoiceFirm');
    }

    componentDidMount() {
        let queryString = new URLSearchParams(this.props.location.search);
        if (queryString.get('new')) {
            window.scrollTo(0, document.body.scrollHeight);
        }

        this.getAsyncFirmOptions('', (options) => {
            this.firmOptions = options;
            this.setState({firmOptionsLoading: false});
        });
    }


    getAsyncFirmOptions = (inputValue, callback) => {
        let queryString = new URLSearchParams();

        queryString.set('instance', this.props.instance.id);
        queryString.set('q', inputValue);

        this.firmApi.getList(queryString.toString())
        .then((response) => {
            return callback(response.data.results.map( (val) => ({label: val.name, value: val.url}) ));
        });

    }

    onFirmChange = (val) => {
        this.setState({firm: val.value, errors: {...this.state.errors, firm: null}});
    }

    showAddFirmFlyout = (e) => {
        this.setState({firmFlyoutIsOpen: true, editingFirm: false});
    }

    showEditFirmFlyout = (e) => {
        this.setState({firmFlyoutIsOpen: true, editingFirm: true})
    }

    handleCloseFirmFlyout = (e) => {
        this.setState({firmFlyoutIsOpen: false, editingFirm: false});
    }

    onDiscountChange = (e) => {
        let val = e.target.value;
        let errors = {...this.state.errors};

        if (isNaN(val)) {
            errors.disc_pct = 'Enter a number';

        } else if (val < 0 || val > 100) {
            errors.disc_pct = 'Enter a number between 0 and 100';
        } else {
            errors.disc_pct = '';
        }

        this.setState({
            disc_pct: val,
            errors: errors
        });
    }

    addService = () => {
        let newService = {description: '', qty: '', rate: '', 'tax_gst': '', 'tax_igst': '', hsn: ''};

        this.setState({
            services: this.state.services.concat([{...newService}]),
            serviceErrors: this.state.serviceErrors.concat([{...newService}])
        });
    }

    removeService = (idx) => {

        this.setState({
            services: this.state.services.filter((service, index) => idx !== index),
            serviceErrors: this.state.serviceErrors.filter((error, index) => idx !== index)
        });
    }

    handleServiceChange = (e, idx) => {
        let errors = [...this.state.serviceErrors];

        let services = this.state.services.map((service, index) => {
            if (idx === index) {
                let val = e.target.value;
                let targetName = e.target.name;

                if (targetName.indexOf('description') > 0){
                    errors[idx].description = '';
                    return {...service, description: val};
                }
                else if (targetName.indexOf('rate') > 0){
                    if (isNaN(val))
                        errors[idx].rate = 'Enter a number';
                    else
                        errors[idx].rate = '';

                    return {...service, rate: val};
                }
                else if (targetName.indexOf('qty') > 0){
                    if (isNaN(val))
                        errors[idx].qty = 'Enter a number';
                    else
                        errors[idx].qty = '';

                    return {...service, qty: val};
                }
                else if (targetName.indexOf('tax_gst') > 0) {
                    if (isNaN(val))
                        errors[idx].tax_gst = 'Enter a number';
                    else
                        errors[idx].tax_gst = '';

                    return {...service, tax_gst: val};
                }
                else if (targetName.indexOf('tax_igst') > 0) {
                    if (isNaN(val))
                        errors[idx].tax_igst = 'Enter a number';
                    else
                        errors[idx].tax_igst = '';

                    return {...service, tax_igst: val};
                }
                else if (targetName.indexOf('hsn') > 0) {
                    errors[idx].hsn = '';
                    return {...service, hsn: val};
                }
            }
            return service;
        });

        this.setState({services: services, errors: errors});
    }

    getSubtotal = () => {
        let serviceAmts = this.state.services.map((service) => 
            getServiceTotal(service.qty, service.rate, service.tax_gst, service.tax_igst).total);

        return Math.ceil(serviceAmts.reduce((accumulator, currentValue) => (accumulator + currentValue), 0)).toFixed(2);
    }

    getAmount = () => {
        // get amount without tax
        let serviceAmts = this.state.services.map((service) => 
            getServiceTotal(service.qty, service.rate, service.tax_gst, service.tax_igst).amount);

        return serviceAmts.reduce((accumulator, currentValue) => (accumulator + currentValue), 0).toFixed(2);
    }

    getDiscount = () => {
        if (!this.state.disc_pct || isNaN(this.state.disc_pct))
            return Number(0).toFixed(2);

        return (this.getAmount() * this.state.disc_pct / 100).toFixed(2);
    }

    getTotal = () => {
        return Math.ceil(this.getSubtotal() - this.getDiscount()).toLocaleString('hi') + '.00';//.toFixed(2);
    }

    handleFormData = (formData) => {
        let data = {
            instance: this.state.instance,
            firm: this.state.firm,
            client_name: formData.get('client_name'),
            client_address: formData.get('client_address'),
            client_phone: formData.get('client_phone'),
            client_gstin: formData.get('client_gstin'),
            po_num: formData.get('po_num')
        };

        if (formData.get('po_date'))
            data.po_date = formData.get('po_date')

        if (this.state.disc_pct)
            data.disc_pct = this.state.disc_pct;

        let errors = {...this.state.errors};

        let isValid = true;

        let required_fields = ['firm', 'client_name'];

        for (let i = 0; i < required_fields.length; i++) {
            let field = required_fields[i];

            if (!data[field]) {
                isValid = false;
                errors[field] = 'This field is required';
            } else {
                errors[field] = '';
            }
        }

        if (this.state.errors.disc_pct)
            isValid = false;

        let serviceErrors = this.state.serviceErrors.concat();

        let required_serviceFields = ['description', 'qty', 'rate'];

        let services = this.state.services.map((service, index) => {
            for (let i = 0; i < required_serviceFields.length; i++) {
                let field = required_serviceFields[i];
                if (service[field].trim().length < 1) {
                    serviceErrors[index][field] = 'This field is required';
                }
            }
            return service;
        });

        for (let i = 0; i < serviceErrors.length; i++) {

            if (!isValid)
                break;

            let serviceError = serviceErrors[i];

            for (let field in serviceError) {
                if (serviceError.hasOwnProperty(field)) {
                    if (serviceError[field].trim().length > 0) {
                        isValid = false;
                        break;
                    }
                }
            }
        }

        if (this.props.quotation) {
            data['quotation_type'] = formData.get('quotation_type') || null;
        }

        this.setState({errors: errors, serviceErrors: serviceErrors});

        return {data: {...data, services: JSON.stringify(services)}, isValid: isValid};

    }

    setErrors = (errors) => {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit = (response, addAnother) => {
        let newPath = reverse(URLS.invoice_edit, {id: response.data.id, instance: this.props.instance.slug});

        if (this.props.quotation)
            newPath = reverse(URLS.quotation_edit, {id: response.data.id, instance: this.props.instance.slug});

        if (newPath !== this.props.location.pathname)
            this.props.history.push(newPath + "?new=true");
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(this.props.quotation ? URLS.quotation_add : URLS.invoice_add);
    }

    afterDelete = (response) => {
        let newPath = reverse(URLS.invoice_all, {instance: this.props.instance.slug});

        if (this.props.quotation)
            newPath = reverse(URLS.quotation_all, {instance: this.props.instance.slug})

        this.props.history.push(newPath);
    }

    getClientOptions = (inputValue, callback) => {
        if (this.state.errors.client_name)
            this.setState({errors: {...this.state.errors, client_name: ''}});

        if (inputValue.trim() === '') {
            return callback([]);
        }


        let queryString = new URLSearchParams();

        queryString.set('instance', this.props.instance.id);
        queryString.set('q', inputValue);

        this.clientApi.getList(queryString.toString())
        .then((response) => {
            return callback(response.data.map( (val) => {
                let extraData = ''
                if (val.info.address)
                    extraData = extraData + val.info.address.replace(/(?:\r\n|\r|\n)/g, ' ');
                if (val.info.phone)
                    extraData = extraData + '\nPhone: ' + val.info.phone;
                if (val.info.gstin)
                    extraData = extraData + ' | GSTIN: ' + val.info.gstin;

                return {
                    label: val.name, 
                    value: val.id,
                    extraData: extraData,
                    info: val.info
                };
            } ));
        })
        .catch((error) => {
            // show error to user if failed to load assignee list
            toast.error('Failed to load Client names. Slow internet?');
            console.log(error);

        });
    }

    onTypeHeadSelect = (selectedOption) => {
        this.setState({
            client_name: selectedOption.info.name,
            client_address: selectedOption.info.address || '',
            client_phone: selectedOption.info.phone || '',
            client_gstin: selectedOption.info.gstin || '',
        });
    }

    render() {
        return (
            <>
            <GenericForm 
                isNew={this.props.initialData ? false : true}
                entityId={this.props.initialData ? this.props.initialData.id : null}
                api={this.props.api}
                instance={this.props.instance}
                handleFormData={this.handleFormData}
                afterSubmit={this.afterSubmit}
                afterDelete={this.afterDelete}
                afterCancel={this.afterDelete}
                setErrors={this.setErrors}
                permissions={this.props.permissions}
                extraButton={this.props.initialData ? <a href={this.props.initialData.pdf_url} className="btn outline-primary" target="_blank" rel="noopener noreferrer"><Printer /> Print</a> : null}
            >
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6">
                            <FormAsyncSelectInput name="firm" placeholder="Firm" 
                                label="Firm"
                                required={true}
                                defaultValue={this.state.firm}
                                error={this.state.errors.firm}
                                variant="fancy"
                                options={this.firmOptions}
                                onChangeCallback={this.onFirmChange}
                                isLoading={this.state.firmOptionsLoading}
                                loadOptions={this.getAsyncFirmOptions}
                                isClearable={true}
                            />
                        </div>

                        <FirmInfo firm={this.state.firm} instance={this.props.instance} />

                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Typeahead
                                name="client_name"
                                label="Client name"
                                placeholder="Client name"
                                defaultValue={this.state.client_name}
                                loadOptions={this.getClientOptions}
                                onOptionSelect={this.onTypeHeadSelect}
                                error={this.state.errors.client_name}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <FormTextArea
                                name="client_address"
                                label="Client address"
                                placeholder="Client address"
                                value={this.state.client_address}
                                onChange={(e) => this.setState({client_address: e.target.value})}
                                rows={6}
                                errors={this.state.errors.client_address}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-3">
                            <FormInput
                                name="client_phone"
                                label="Client phone"
                                placeholder="Client phone"
                                value={this.state.client_phone}
                                onChange={(e) => this.setState({client_phone: e.target.value})}
                                error={this.state.errors.client_phone}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <FormInput
                                name="client_gstin"
                                label="Client GSTIN"
                                placeholder="Client GSTIN"
                                value={this.state.client_gstin}
                                onChange={(e) => this.setState({client_gstin: e.target.value})}
                                error={this.state.errors.client_gstin}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-3">
                            <FormInput
                                name="po_num"
                                label="Purchase order no."
                                placeholder="Purchase order no."
                                defaultValue={this.state.po_num}
                                error={this.state.errors.po_num}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <FormInput
                                type="date"
                                name="po_date"
                                label="Purchase order date"
                                placeholder="Purchase order date"
                                defaultValue={this.state.po_date}
                                error={this.state.errors.po_date}
                            />
                        </div>
                    </div>
                    {this.props.quotation && 
                        <div className="row">
                            <div className="col-12">
                                <FormRadioInput 
                                    name="quotation_type" 
                                    label="Quotation type" 
                                    variant="inline"
                                    defaultValue={this.state.quotation_type}
                                    options={[
                                        {label: 'None', value: ''},
                                        {label: 'Credit Note', value: 1},
                                        {label: 'Debit Note', value: 2},
                                    ]}
                                    required={false}
                                />
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col-12">
                            <h3 className="form-section-header">Services</h3>
                            <InvoiceServices 
                                services={this.state.services} 
                                errors={this.state.serviceErrors}
                                removeService={this.removeService}
                                onChange={this.handleServiceChange}
                            />
                        </div>
                        <div className="col-12">
                            <Button 
                                className="btn-sm secondary" 
                                onClick={this.addService}
                            >
                                <Plus /> Add Service
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mt-3">
                            <FormInput 
                                type="text"
                                name="disc_pct"
                                label="Discount (%)"
                                placeholder="Discount"
                                helpText="Discount is calculated excluding tax"
                                value={this.state.disc_pct}
                                onChange={this.onDiscountChange}
                                error={this.state.errors.disc_pct}
                            />
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4 col-sm-3 col-md-2 col-lg-2">
                                    <p><strong>Subtotal</strong>:</p>
                                    <p><strong>Discount</strong>:</p>
                                    <p><strong>Total</strong>:</p>
                                </div>
                                <div className="col-6">
                                    <p>Rs {this.getSubtotal()}</p>
                                    <p>Rs {this.getDiscount()} (excl. tax)</p>
                                    <p>Rs {this.getTotal()}</p>
                                    <p><small className="text-muted">*Total rounded-up to next whole number</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.initialData ?
                    <div className="col-12 form-footer">
                        <p>Created on: {this.props.initialData.date}</p>
                    </div>
                    : null
                }

            </GenericForm>
            </>
        );
    }
}

const InvoiceForm = withRouter(_InvoiceForm);


class InvoiceServices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            currentHsnInput: -1,
            hsnClickEvent: null
        };
    }

    onHsnInputClick = (e, index) => {
        this.setState({showModal: true, currentHsnInput: index});
    }

    onHsnSelect = (code) => {
        this.setState((state) => {
            let hsnClickEvent = {target: {value: code, name: 'input_hsn'}};
            return {hsnClickEvent: hsnClickEvent};
        }, () => {
            this.props.onChange(this.state.hsnClickEvent, this.state.currentHsnInput);
            this.handleCloseModal();
        });
    }

    handleCloseModal = () => {
        this.setState({showModal: false, currentHsnInput: -1, hsnClickEvent: null});
    }

    render() {
        let serviceRows = this.props.services.map((service, index) => {
            let amount = getServiceTotal(service.qty, service.rate, service.tax_gst, 
                service.tax_igst).total.toLocaleString('hi') || '';

            // amount = .toFixed(2) // but locale doesn't work after toFixed

            return (
                <div className="form-row bordered dismissable" key={index}>
                    <div className="col-lg-4">
                        <FormTextArea 
                            name={"service_description_" + index}
                            type="text" 
                            placeholder="Description"
                            label="Description"
                            rows={2}
                            value={service.description} 
                            onChange={(e) => this.props.onChange(e, index)}
                            error={this.props.errors[index].description}
                            autoHeight={true}
                            required={true}
                        />
                    </div>
                    <div className="col-6 col-md-2 col-lg-1">
                        <FormInput 
                            name={"service_qty_" + index}
                            type="text" 
                            placeholder="Qty"
                            label="Qty"
                            value={service.qty} 
                            onChange={(e) => this.props.onChange(e, index)}
                            error={this.props.errors[index].qty}
                            required={true}
                        />
                    </div>
                    <div className="col-6 col-md-3 col-lg-2">
                        <FormInput 
                            name={"service_rate_" + index}
                            type="text" 
                            placeholder="Rate"
                            label="Rate"
                            value={service.rate} 
                            onChange={(e) => this.props.onChange(e, index)}
                            error={this.props.errors[index].rate}
                            required={true}
                        />
                    </div>
                    <div className="col-6 col-md-2 col-lg-1">
                        <FormInput 
                            name={"service_tax_gst_" + index}
                            type="text" 
                            placeholder="GST"
                            label="GST(%)"
                            value={service.tax_gst} 
                            onChange={(e) => this.props.onChange(e, index)}
                            error={this.props.errors[index].tax_gst}
                        />
                    </div>
                    <div className="col-6 col-md-2 col-lg-1">
                        <FormInput 
                            name={"service_tax_igst_" + index}
                            type="text" 
                            placeholder="IGST"
                            label="IGST(%)"
                            value={service.tax_igst} 
                            onChange={(e) => this.props.onChange(e, index)}
                            error={this.props.errors[index].tax_igst}
                        />
                    </div>
                    <div className="col-6 col-md-2 col-lg-1">
                        <FormInput 
                            name={"service_hsn_" + index}
                            type="text" 
                            placeholder="HSN"
                            label="HSN"
                            value={service.hsn} 
                            onChange={(e) => this.props.onChange(e, index)}
                            error={this.props.errors[index].hsn}
                            onClick={(e) => this.onHsnInputClick(e, index)}
                        />
                    </div>
                    <div className="col-md-3 col-lg-2">
                        <FormInput 
                            name={"service_amount_" + index}
                            type="text" 
                            placeholder="Amount"
                            label="Amt."
                            value={amount} 
                            readOnly
                        />
                    </div>
                    {this.props.services.length === 1 ? null : <CloseButton onClick={() => this.props.removeService(index)} />}
                </div>
            );
        });

        return (
            <>
            {serviceRows}
            <Modal 
                   isOpen={this.state.showModal}
                   handleCloseModal={this.handleCloseModal}
                   title='Choose HSN Code'
                   vAlign='top'
                >
                    <HsnTable onHsnSelect={this.onHsnSelect} />
                </Modal>
            </>
        );
    }
}

class HsnTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchVal: '',
            focussed: false,
            items: [
                {
                    code: '4817',
                    name: 'Envelope',
                    pk: 1
                },
                {
                    code: '4817',
                    name: 'Letter heads',
                    pk: 2
                },
                {
                    code: '4820',
                    name: 'Diary, Note Pad',
                    pk: 3
                },
                {
                    code: '4820', 
                    name: 'Folders ',
                    pk: 4
                },
                {
                    code: '4820', 
                    name: 'Invoice, Books, Letter pad',
                    pk: 5
                },
                {
                    code: '4821', 
                    name: 'Stickers, Packing Material ', 
                    pk: 6
                },
                {
                    code: '4821', 
                    name: 'Inserts', 
                    pk: 7
                },
                {
                    code: '4901', 
                    name: 'Leaflets (Single Sheet)', 
                    pk: 8
                },
                {
                    code: '4901', 
                    name: 'Brouchers (Multiple Sheets)', 
                    pk: 9
                },
                {
                    code: '4909', 
                    name: 'Visiting Card', 
                    pk: 10
                },
                {
                    code: '4901', 
                    name: 'Wedding/ Invitation card', 
                    pk: 11
                },
                {
                    code: '4910', 
                    name: 'Calenders', 
                    pk: 12
                },
                {
                    code: '4911', 
                    name: 'Poster', 
                    pk: 13
                },
                {
                    code: '4911', 
                    name: 'Inlay Cards', 
                    pk: 14
                },
                {
                    code: '4902', 
                    name: 'Booklets, Souvenir/ Magzine(Monthly)', 
                    pk: 15
                },
                {
                    code: '4901', 
                    name: 'Medical Booklet', 
                    pk: 16
                },
                {
                    code: '4820', 
                    name: 'Bill/ Tax Invoice /Cash Memo', 
                    pk: 17
                },
                {
                    code: '4820', 
                    name: 'Delivery Challan Book', 
                    pk: 18
                },
                {
                    code: '9003', 
                    name: 'Frame/ Ffitting (Wood/Metal)', 
                    pk: 19
                },
                {
                    code: '4817', 
                    name: 'I Card', 
                    pk: 20
                },
                {
                    code: '8443', 
                    name: 'Offset Printing Labour', 
                    pk: 21
                },
                {
                    code: '8443', 
                    name: 'Screen Printing Labour', 
                    pk: 22
                },
                {
                    code: '6305', 
                    name: 'Jute Bag/Cloth Bag/Paper Bag', 
                    pk: 23
                },
                {
                    code: '4821', 
                    name: 'All type of Paper printing', 
                    pk: 24
                },
                {
                    code: '4821', 
                    name: 'Coupon / Tickets', 
                    pk: 25
                },
                {
                    code: '3906', 
                    name: 'Acrylic polymers in primary form', 
                    pk: 26
                },
                {
                    code: '3920', 
                    name: 'Acrylic Plastic sheet & cut', 
                    pk: 27
                },
                {
                    code: '3920', 
                    name: 'PVC Lamination Flex Rolls / Sheets', 
                    pk: 28
                },
                {
                    code: '3920', 
                    name: 'PVC Sheet', 
                    pk: 29
                },
                {
                    code: '3920', 
                    name: 'Acrylic Sheet off cuts', 
                    pk: 30
                },
                {
                    code: '3920', 
                    name: 'PVC Sheeting in Rolls', 
                    pk: 31
                },
                {
                    code: '3921', 
                    name: 'PVC Flex Material', 
                    pk: 32
                },
                {
                    code: '3921', 
                    name: 'Acrylic Sheet', 
                    pk: 33
                },
                {
                    code: '3926', 
                    name: 'Acrylic Sign Board', 
                    pk: 34
                },
                {
                    code: '3926', 
                    name: 'Plstic Photo ID CARD', 
                    pk: 35
                },
                {
                    code: '4911', 
                    name: 'Sunboard Plain or Printed', 
                    pk: 36
                },
                {
                    code: '4911', 
                    name: 'Promotional Item Like T- Shirt, Caps, Mugs, Pen Drives', 
                    pk: 37
                },
                {
                    code: '9405', 
                    name: 'LED Module, Power Supply, LED Strips (Rigid or Flexible)', 
                    pk: 38
                },
                {
                    code: '9405', 
                    name: 'ACP Signage with Channel Letter, 3D Letter, Glow Sign box clipon Frames, Frames Boxes, toempotes, Lollypops, channel letters, All Signages, LED Display Item', 
                    pk: 39
                },
            ]
        };

        this.input = React.createRef();
    }

    handleBlur = (e) => {
        this.setState({focussed: false});
    }

    handleFocus = (e) => {
        this.setState({focussed: true});
    }

    handleSearchChange = (e) => {
        this.setState({searchVal: e.target.value});
    }

    getTableItems = () => {
        let items = null;
        let query = this.state.searchVal.trim().toLowerCase();

        if (query.length)
            items = this.state.items.filter((item) => item.name.toLowerCase().includes(query));
        else 
            items = this.state.items;

        return items.map((item) => 
            <tr key={item.pk} onClick={(e) => this.onRowClick(e, item.code)}>
                <td>{item.name}</td><td>{item.code}</td>
            </tr>
            );     
    }

    onRowClick = (e, code) => {
        this.props.onHsnSelect(code);
    }


    render() {
        return (
            <div className="row">
                <div className="col-12 toolbar hsn-toolbar">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span 
                                className={this.state.focussed ? "input-group-text focussed" : "input-group-text"}
                                onClick={this.handleFocus}
                            >
                                <Search />
                            </span>
                        </div>
                        <input 
                            type="text" 
                            className="form-control icon-prepend" 
                            placeholder="Search by service name..."
                            autoFocus={this.state.focussed}
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            ref={this.input}
                            value={this.state.searchVal} 
                            onChange={this.handleSearchChange} 
                        />
                    </div>
                </div>
                <div className="col-12">
                    <table className="hsn-table">
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getTableItems()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}


class FirmInfo extends Component {
    constructor(props) {
        super(props);

        this.api = new API('invoiceFirm');
    } 

    toRender(firm) {
        return (
             <div>
                <p><strong>Name:</strong> {firm.name}</p>
                <p><strong>ID Code:</strong> {firm.invoice_id_prefix}</p>
                <p><strong>Phone:</strong> {firm.phone}</p>
                <p><strong>Email:</strong> {firm.email}</p>
                <p><strong>Address:</strong> <br /> <span className="nlbr">{firm.address}</span></p>
                <p><strong>GSTIN:</strong> {firm.gstin}</p>
                <p><strong>MSME:</strong> {firm.msme}</p>
                <p><strong>Bank name:</strong> {firm.bank_name}</p>
                <p><strong>Acc no.:</strong> {firm.acc_no}</p>
                <p><strong>RTGS/IFS code:</strong> {firm.ifs_code}</p>
                <p><strong>PAN no.:</strong> {firm.pan_no}</p>
                <p><strong>Signature:</strong> 
                    {firm.signature ? 
                        <>
                            <br /> 
                            <img className="signature-thumbnail" src={firm.signature} 
                                alt="Current signature"
                            />
                        </> 
                        : 'None'
                    }
                </p>
            </div>
        );
    }

    render() {
        return (
            <ObjectInfoFlyout 
                object={this.props.firm} 
                title={'Firm details'}
                api={this.api}
                queryString={'instance=' + this.props.instance.id}
                render={this.toRender}
            />
        );
    }
}

