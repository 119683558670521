import React from 'react';

export default function Alert(props) {
    return (
        <div className={"alert " + props.className}>
            {props.children}
        </div>
    );
}

export function RequestError(props) {
    let title, message, showRetryBtn;

    switch (props.errorType) {
        case 404: 
            title = "404 Not Found";
            message = "This page or object doesn't exist";
            showRetryBtn = false;
            break;
        case 500:
            title = "500 Internal Server Error";
            message = "Something went wrong on the server. Contact administrator."
            showRetryBtn = false;
            break;
        case 'CONNECTION_ERROR':
            title = "Connection Error";
            message = "Check your Internet connection and try again.";
            showRetryBtn = true;
            break;
        default:
            title = "Error";
            message = "Something wen't wrong. Try again.";
            showRetryBtn = true;
            break;
    }

    let retryBtn;

    if (showRetryBtn) {
        retryBtn = (
            <p>
                <button className="btn outline-primary" onClick={props.retryCallback}>
                    Try Again
                </button>
            </p>
        );
    }
    return (
        <Alert className="error">
            <h3>{title}</h3>
            <p>{message}</p>
            {retryBtn}
        </Alert>
    );
}
