import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm} from '../generics';
import {FormInput, FormCheckInput, FormTextArea, Typeahead, toast} from '../ui';
import {reverse} from '../utils.js';


export default class TaskBase extends Component {
    constructor(props) {
        super(props);

        this.filters = [
            {
                label: 'Status', name: 'completed', type: 'radio', 
                options: [
                    {label: 'All', value: '', default: true}, 
                    {label: 'Completed', value: 'true'}, 
                    {label: 'Pending', value: 'false'},
                ]
            },
            {
                label: 'Today', name: 'today', type: 'checkbox', 
                options: [
                    {label: 'Yes', value: 'true', default: false}, 
                ]
            },
            {
                label: 'Sorting', name: 'o', type: 'radio',
                options: [
                    {label: 'Newest first', value: '-created_on', default: true},
                    {label: 'Oldest first', value: 'created_on'},
                ]
            }
        ];
    }

    tableCheckCallback = (checkedIds) => {
        this.checkedIds = checkedIds.concat();
    }



    render() {
        let api = new API('task');
        let permissions = this.props.permissions.task;

        return (
            <GenericBasePage 
                title="Tasks"
                api={api}
                instance={this.props.instance}
                list_url={URLS.task_all}
                add_url={URLS.task_add}
                edit_url={URLS.task_edit}
                listFilters={this.filters}
                tableRowURI={URLS.task_edit}
                tableCols={['details', 'given_to', 'due_date_display', 'created_on', 'completed']}
                tableTh={['Task', 'Given to', 'Due date', 'Created on', 'Completed']}
                tableThWidths={['175', '150', '100', '175', '100']}
                tableColCls={['col-12', 'col-7', 'col-5', 'col-7', 'col-5']}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <TaskForm api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                editForm={(initialData) => <TaskForm initialData={initialData} api={api} instance={this.props.instance} permissions={permissions} handleLogout={this.handleLogout} />}
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}



class _TaskForm extends Component {
    constructor(props) {
        super(props);

        let initialData = {
            details: '',
            given_to: '',
            due_date: '',
            completed: '',
            ...props.initialData
        };

        this.state = {
            details: initialData.details,
            given_to: initialData.given_to,
            due_date: initialData.due_date,
            completed: initialData.completed,
            errors: {
                details: null,
                given_to: null,
                due_date: null,
                completed: null,
            },
            assigneeOptionsLoading: true,
        };

        this.assigneeOptions = [];

        this.handleFormData = this.handleFormData.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.afterDelete = this.afterDelete.bind(this);
        this.setErrors = this.setErrors.bind(this);

        this.assigneeApi = new API('taskAssignee');

    }

    componentDidMount() {
        this.getAsyncAssigneeOptions('', (options) => {
            this.assigneeOptions = this.assigneeOptions.concat(options);
            this.setState({assigneeOptionsLoading: false});
        });
    }

    onGivenToChange = (val) => {
        this.setState({given_to: val.value, errors: {...this.state.errors, given_to: null}});
    }

    getAsyncAssigneeOptions = (inputValue, callback) => {
        let queryString = new URLSearchParams();

        queryString.set('instance', this.props.instance.id);

        this.assigneeApi.getList(queryString.toString())
        .then((response) => {
            return callback(response.data.map( (val) => ({label: val.name, value: val.name}) ));
        })
        .catch((error) => {
            // show error to user if failed to load assignee list
            toast.error('Failed to load names for "Given To" field. Slow internet?');
            console.log(error);

        });
    }

    getAssigneeOptions = (inputValue, callback) => {
        if (this.state.errors.given_to)
            this.setState({errors: {...this.state.errors, given_to: ''}});

        if (inputValue.trim() === '') {
            return callback([]);
        }

        let name = inputValue.toLowerCase(); //inputValue.split(',');
        //name = name[name.length - 1].trim().toLowerCase();

        let filteredOptions = [];
        if (name) {
            filteredOptions = this.assigneeOptions.filter((val) => 
                val.label.toLowerCase().startsWith(name)
            );
        }

        return callback(filteredOptions.map( (val) => {
            return {
                label: val.label, 
                value: val.value,
                extraData: null,
            };
        }));
    }

    onAssigneeSelect = (selectedOption) => {
        this.setState({
            given_to: selectedOption.value,
        });
    }

    handleFormData(formData) {
        let data = {
            instance: this.props.instance.id,
            details: formData.get('details'),
            given_to: formData.get('given_to'),
            due_date: formData.get('due_date'),
            completed: formData.get('completed') || false,
        };

        let required_fields = ['details', 'given_to', 'due_date'];

        let isValid = true;

        let errors = {...this.state.errors};

        let field = '';
        for (let i = 0; i < required_fields.length; i++) {
            field = required_fields[i];

            if (!data[field].length) {
                isValid = false;
                errors[field] = 'This field is required';
            } else {
                errors[field] = '';
            }
        }

        this.setState({errors: errors});

        return {data: data, isValid: isValid};
    }

    setErrors(errors) {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit(response, addAnother) {
        let newPath = reverse(URLS.task_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname)
            this.props.history.push(newPath);
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.task_add);
    }

    afterDelete(response) {
        this.props.history.push(reverse(URLS.task_all, {instance: this.props.instance.slug}));
    }

    getMinDate() {
        let d = new Date();
        return d.toJSON().split('T')[0];
    }


    render() {
        return (
            <GenericForm 
                isNew={this.props.initialData ? false : true}
                permissions={this.props.permissions}
                instance={this.props.instance}
                entityId={this.props.initialData ? this.props.initialData.id : null}
                api={this.props.api}
                handleFormData={this.handleFormData}
                afterSubmit={this.afterSubmit}
                afterDelete={this.afterDelete}
                afterCancel={this.afterDelete}
                setErrors={this.setErrors}
            >
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6">
                            <FormTextArea 
                                name="details" 
                                placeholder="Task details"
                                label="Task details"
                                defaultValue={this.state.details}
                                error={this.state.errors.details}
                                required={true}
                                autoHeight={true}
                                readOnly={!this.props.permissions.can_add}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <FormInput 
                                name="due_date" 
                                placeholder="Due date"
                                label="Due date"
                                type="date"
                                min={this.getMinDate()}
                                defaultValue={this.state.due_date}
                                error={this.state.errors.due_date}
                                required={true}
                                readOnly={!this.props.permissions.can_add}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-sm-9 col-md-6">
                            <Typeahead
                                name="given_to"
                                label={this.props.initialData ? "Given to" : "Give to"}
                                placeholder={this.props.initialData ? "Given to" : "Give to"}
                                defaultValue={this.state.given_to}
                                loadOptions={this.getAssigneeOptions}
                                onOptionSelect={this.onAssigneeSelect}
                                error={this.state.errors.given_to}
                                required={true}
                                readOnly={!this.props.permissions.can_add}
                            />
                        </div>
                    </div>

                    {this.props.initialData &&
                        <div className="row">
                            <div className="col-12 col-sm-9 col-md-6">
                            <FormCheckInput 
                                name="completed" 
                                label="Completed"
                                defaultChecked={this.state.completed}
                            />
                            </div>
                        </div>
                    }
                </div>

                {this.props.initialData ?
                    <div className="col-12 form-footer">
                        <p>Created on: {this.props.initialData.created_on}</p>
                    </div>
                    : null
                }

            </GenericForm>
        );
    }
}

const TaskForm = withRouter(_TaskForm);
