import React, {Component} from 'react';
import ReactModal from 'react-modal';
import {X} from 'react-feather';
import {Button} from './buttons.js';


export class FlyoutBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasScroll: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isOpen && !prevProps.isOpen) {
            let documentWidth = document.documentElement.clientWidth;
            let windowWidth = window.innerWidth;
            let scrollBarWidth = windowWidth - documentWidth;

            document.body.style.cssText = "overflow: hidden; padding-right: " + scrollBarWidth + "px;";
            document.getElementsByClassName('top-nav')[0].style.cssText = "padding-right: " + scrollBarWidth + "px;";
        }

        if (!this.props.isOpen && prevProps.isOpen) {
            document.body.style.cssText = "";
            document.getElementsByClassName('top-nav')[0].style.cssText = "";
        }

    }

    setContentRef = (node) => {
        if (!this.flyoutRef)
            this.flyoutRef = node;
    }

    handleAfterOpen = () => {
        if (this.flyoutRef) {
            let content = this.flyoutRef.getElementsByClassName('flyout-content')[0];
            let body = content.getElementsByClassName('flyout-body')[0];

            if (body) {
                if (body.clientHeight < body.scrollHeight && !this.state.hasScroll)
                    this.setState({hasScroll: true});
            }
        }
    }

    render() {
        return (
                <ReactModal 
                    contentLabel={this.props.title}
                    ariaHideApp={false}
                    overlayClassName='flyout-overlay'
                    bodyOpenClassName='modal-open'
                    className='flyout col-lg-4 col-md-6 col-11'
                    isOpen={this.props.isOpen}
                    onRequestClose={this.props.handleCloseFlyout}
                    closeTimeoutMS={200}
                    shouldCloseOnOverlayClick={!this.props.blocking}
                    contentRef={this.setContentRef}
                    onAfterOpen={this.handleAfterOpen}
                >
                    <div 
                        className={this.state.hasScroll ? "flyout-content has-scroll" : "flyout-content"} 
                    >
                        <div className="flyout-header">
                            {this.props.title}
                            <button className="flyout-close" onClick={this.props.handleCloseFlyout}><X /></button>
                        </div>
                        {this.props.children}
                    </div>
            </ReactModal>
        );
    }
}


export default class Flyout extends Component {
    render() {
        return (
                <FlyoutBase
                    title={this.props.title}
                    isOpen={this.props.isOpen}
                    handleCloseFlyout={this.props.handleCloseFlyout}
                    blocking={this.props.blocking}
                >
                    <div className="flyout-body">
                        {this.props.children}
                    </div>
                    <div className="flyout-footer">
                        {this.props.actionBtnLabel ? 
                          <Button 
                              className={this.props.actionBtnClassName}
                              onClick={this.props.actionBtnOnClick}
                              loading={this.props.actionBtnLoading}
                          >
                              {this.props.actionBtnLabel}
                          </Button>
                          : null
                        }
                        <Button className="default" onClick={this.props.handleCloseFlyout}>{this.props.cancelBtnLabel || 'Cancel'}</Button>
                    </div>
            </FlyoutBase>
        );
    }
}
