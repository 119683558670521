import axios from 'axios';


var BASE_API_URL;
//var BASE_MEDIA_URL;

if (process.env.NODE_ENV === 'production') {
    BASE_API_URL = '/api/v1/';
    //BASE_MEDIA_URL = '';
} else {
    BASE_API_URL = 'http://localhost:8000/api/v1/';
    //BASE_API_URL = 'http://192.168.15.201:8000/api/v1/';
    //BASE_MEDIA_URL = 'http://localhost:8000';
}


const API_URLS = {
    init: BASE_API_URL + 'init/',
    

    login: BASE_API_URL + 'login/',
    logout: BASE_API_URL + 'logout/',

    instance: BASE_API_URL + 'instance/',

    home: BASE_API_URL + 'home/',
    
    challan: BASE_API_URL + 'challan/',
    order: BASE_API_URL + 'order/',
    task: BASE_API_URL + 'task/',
    taskAssignee: BASE_API_URL + 'task-assignee/',
    
    invoice: BASE_API_URL + 'invoice/',
    quotation: BASE_API_URL + 'quotation/',
    invoiceFirm: BASE_API_URL + 'invoice-firm/',
    invoiceClient: BASE_API_URL + 'invoice-client/',
    
    tool: BASE_API_URL + 'tool/',
    toolLog: BASE_API_URL + 'tool-log/',

    stockCategory: BASE_API_URL + 'stock-category/',
    stockItem: BASE_API_URL + 'stock-item/',
    stockEntry: BASE_API_URL + 'stock-entry/',
    stockLog: BASE_API_URL + 'stock-log/',

    user: BASE_API_URL + 'user/',
    group: BASE_API_URL + 'group/',
    
    suggest: BASE_API_URL + 'suggest/',

    report: BASE_API_URL + 'report/'
}


let API_CACHE = {
    challan: {},
    order: {},
    invoice: {},
    quotation: {},
    invoiceFirm: {},
    invoiceClient: {},
    tool: {},
    toolLog: {},
    stockCategory: {},
    stockItem: {},
    stockEntry: {},
    stockLog: {},
    user: {},
    group: {},
    task: {},
    suggest: {}
}

let TOKEN = null;


class API {
    constructor(name, instanceId) {
        this.name = name;
        this.instanceId = instanceId;
        this.endpoint = API_URLS[name];
        this.token = TOKEN;

        if (TOKEN) {
            axios.defaults.headers.common['Authorization'] = 'Token ' + TOKEN;
        } else {
            if ((document.cookie.split(';').filter((item) => item.trim().indexOf('auth_token=') === 0)).length) {
                let token = document.cookie.split(';').filter((item) => item.trim().indexOf('auth_token=') === 0)[0].split('=')[1];
                if (token) {
                    TOKEN = token;
                    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
                }
            }
        }

    }

    setToken(token) {
        TOKEN = token;
        this.token = token;
        axios.defaults.headers.common['Authorization'] = null;
    }

    setCache(id, response) {
        API_CACHE[this.name][id] = response;
        // invalidate cache after 2 seconds
        setTimeout(() => this.removeFromCache(id), 2000);
    }

    removeFromCache(id) {
        API_CACHE[this.name][id] = null;
    }

    getFromCache(id) {
        //return API_CACHE[this.name][id];
        return null;
    }

    createEntity(data, queryString) {
        let promise = new Promise((resolve, reject) => {
            axios.post(this.endpoint + '?' + queryString, data)
            .then((response) => {
                /* caching isn't working properly with foreignkey names/labels. 
                Disable it. */
                //API_CACHE[this.name][id] = response;
                //this.setCache(response.data.id, response);
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
        
        return promise;
        //return axios.post(this.endpoint, data);
    }

    deleteEntity(id, queryString) {
        return axios.delete(this.endpoint + id + '/?' + queryString);
    }

    updateEntity(id, data, queryString) {
        return axios.patch(this.endpoint + id + '/?' + queryString, data);
    }

    getList(queryString) {
        if (queryString === undefined)
            queryString = '';

        return axios.get(this.endpoint + '?' + queryString);
    }

    getOne(id, queryString) {
        if (queryString === undefined)
            queryString = '';

        let promise = new Promise((resolve, reject) => {

            //let cached = API_CACHE[this.name][id];
            let cached = this.getFromCache(id);

            if (!cached) {   
                axios.get(this.endpoint + id + '/?' + queryString)
                .then((response) => {
                    //this.setCache(id, response);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
            } else {
                resolve(cached);
            }
        });

        return promise;
    }

    /* basic methods */
    post(endpoint, data) {
        return axios.post(endpoint, data);
    }
}


class SuggestAPI {
    constructor() {
        this.endpoint = API_URLS.suggest;
    }

    suggest(model, q) {
        return axios.get(this.endpoint + '?model=' + model + '&q=' + q);
    }
}


export {
    BASE_API_URL,
    API_URLS,
    API,
    SuggestAPI
}