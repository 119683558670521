import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {URLS} from '../urlconf.js';
import {API} from '../api.js';
import {GenericBasePage, GenericForm} from '../generics';
import {FormInput, FormSelectInput} from '../ui';
import {reverse} from '../utils.js';
import {LENGTH_UNITS, QTY_UNITS, AREA_UNITS, 
    WIDTH_CATEGS, TRACK_LENGTH, TRACK_AREA,
    convertQty, getUnitLabel, unitsAreCompat} from './units.js';


export default class StockInBase extends Component {
    constructor(props) {
        super(props);

        this.filters = [
            {
                label: 'Category', name: 'category', type: 'checkbox', 
                options: []
            },
            {
                label: 'Sorting', name: 'o', type: 'radio',
                options: [
                    {label: 'Name (A-Z)', value: 'name', default: true},
                    {label: 'Name (Z-A)', value: '-name'},
                ]
            }
        ];

        this.categoryOptions = [];
    }

    componentDidMount() {
        let api = new API('stockCategory');

        api.getList('instance=' + this.props.instance.id + "&limit=100")
        .then((response) => {
            this.filters[0] = {
                label: 'Category', name: 'category', type: 'checkbox', 
                options: response.data.results.map((val) => ({label: val.name, value: val.id}) )
            };

            this.categoryOptions = response.data.results.map((val) => ({label: val.name, value: val.url}) );

            this.setState({update: true});
        });

    }

    tableCheckCallback = (checkedIds) => {

    }

    render() {
        let api = new API('stockItem');
        let permissions = this.props.permissions.stockitem;

        return (
            <GenericBasePage 
                title="Stock In"
                api={api}
                instance={this.props.instance}
                list_url={URLS.stockIn_all}
                add_url={URLS.stockIn_add}
                edit_url={URLS.stockIn_edit}
                listFilters={this.filters}
                tableRowURI={URLS.stockIn_edit}
                tableCols={['name_display', 'category_name', 'remaining']}
                tableTh={['Name', 'Category', 'Remaining']}
                tableCheckable={true}
                tableCheckCallback={this.tableCheckCallback}
                permissions={permissions}
                addForm={() => <StockItemForm 
                                api={api} instance={this.props.instance} 
                                permissions={permissions} 
                                handleLogout={this.handleLogout} 
                                categoryOptions={this.categoryOptions} 
                                />
                        }
                editForm={(initialData) => <StockItemForm 
                                            api={api} 
                                            permissions={permissions} 
                                            handleLogout={this.handleLogout} 
                                            initialData={initialData} 
                                            instance={this.props.instance} 
                                            categoryOptions={this.categoryOptions} 
                                            />
                        }
                placeholderFields={4}
                handleLogout={this.props.handleLogout}
            />
        );
    }
}



class _StockItemForm extends Component {
    constructor(props) {
        super(props);

        let initialData = {
            instance: props.instance.id,
            category: '',
            name: '',
            area: '',
            length: '',
            qty: '',
            q_unit: '',
            width: '',
            w_unit: '',
            ...props.initialData
        };

        this.state = {
            instance: initialData.instance,
            name: initialData.name,
            category: initialData.category,
            categoryName: null,

            qty: '',
            q_unit: '',
            width: initialData.width,
            w_unit: initialData.w_unit,

            qtyLabel: 'Qty',

            errors: {
                name: null,
                area: null,
                length: null,
                qty: null,
                q_unit: null,
                width: null,
                w_unit: null
            },
            old_qty: initialData.qty,
            old_q_unit: initialData.q_unit,
        };

        this.handleFormData = this.handleFormData.bind(this);
        this.afterSubmit = this.afterSubmit.bind(this);
        this.afterDelete = this.afterDelete.bind(this);
        this.setErrors = this.setErrors.bind(this);
    }

    componentDidMount() {
        if (this.props.categoryOptions.length && this.state.category) {
            this.setCategoryname();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.categoryOptions.length && !prevProps.categoryOptions.length) {
            this.setCategoryname();
        }
    }

    setCategoryname = () => {
        let category = this.props.categoryOptions.find((item) => item.value === this.state.category);

        if (!category) {
            //category = {label: 'UNDEFINED'};
            category = {label: null, value: ''};
        }

        this.onCategoryChange(category);

        /*this.setState({
            categoryName: category.label
        });
        */
    }

    handleFormData(formData) {
        let data = {
            instance: this.state.instance,
            name: formData.get('name'),
            category: this.state.category,
            qty: this.state.qty,
            q_unit: this.state.q_unit
        };

        if (WIDTH_CATEGS.includes(this.state.categoryName)) {
            data.width = this.state.width;
            data.w_unit = this.state.w_unit;
        }

        let errors = {...this.state.errors};
        let isValid = true;

        for (let i in data) {
            if (['width', 'qty'].includes(i)) {
                if (data[i].length < 1 || isNaN(data[i])) {
                    errors[i] = "Enter a valid number";
                    isValid = false;
                }
            } else if (['w_unit'].includes(i)) {
                if (data[i].length < 1) {
                    errors[i] = "Choose a unit";
                    isValid = false;
                }
            }
        }

        if (data.name.length < 1) {
            errors.name = 'This field is required';
            isValid = false;
        } else {
            errors.name = null;
        }

        if (data.category.length < 1) {
            errors.category = 'This field is required';
            isValid = false;
        } else {
            errors.category = null;
        }

        this.setState({
            errors: errors
        });

        return {data: data, isValid: isValid};
    }

    setErrors(errors) {
        let newErrors = {...this.state.errors, ...errors};
        this.setState({errors: newErrors})
    }

    afterSubmit(response, addAnother) {
        let newPath = reverse(URLS.stockIn_all, {instance: this.props.instance.slug});
        if (newPath !== this.props.location.pathname) {
            let list_filters = new URLSearchParams(this.props.location.search).get('_list_filters') || '';
            newPath += list_filters
            this.props.history.push(newPath);
        }
        else
            this.setState({errors: {}});

        if (addAnother)
            this.props.history.push(URLS.stockIn_add);
    }

    afterDelete(response) {
        this.props.history.push(reverse(URLS.stockIn_all, {instance: this.props.instance.slug}));
    }

    onCategoryChange = (val) => {
        let qtyLabel = 'Qty';
        if (TRACK_AREA.includes(val.label))
            qtyLabel = 'Area';
        else if (TRACK_LENGTH.includes(val.label))
            qtyLabel = 'Length';

        this.setState({
            category: val.value, categoryName: val.label, 
            qtyLabel: qtyLabel,
            errors: {...this.state.errors, category: ''},
        });
    }

    onQtyChange = (e) => {
        let val = e.target.value;
        let error = null;

        if (isNaN(val))
            error = "Enter a valid number";

        this.setState({
            qty: val, 
            errors: {...this.state.errors, qty: error}
        });
    }
    onQtyUnitChange = (val) => {
        this.setState({
            q_unit: val.value,
            errors: {...this.state.errors, q_unit: null}
        });
    }

    onWidthChange = (e) => {
        let error = null;
        if (isNaN(e.target.value))
            error = "Enter a valid number";

        this.setState({
            width: e.target.value,
            errors: {...this.state.errors, width: error}
        });
    }
    onWidthUnitChange = (val) => {
        this.setState({
            w_unit: val.value,
            errors: {...this.state.errors, w_unit: null}
        });
    }
    onOldQtyUnitChange = (val) => {
        let qty = this.state.old_qty;
        qty = convertQty(qty, this.state.old_q_unit, val.value);
        this.setState({
            old_qty: qty,
            old_q_unit: val.value
        });
    }
    getNewTotal = () => {
        let newTotal = null;

        if (!this.props.initialData)
            return newTotal;

        if (!this.state.qty || isNaN(this.state.qty))
            return newTotal;

        let label = this.state.qtyLabel;
        let old_unit = this.state.old_q_unit;

        if (!old_unit && label === 'Area') {
            old_unit = 'sq_m';
        }
        else if (!old_unit && label === 'Length') {
            old_unit = 'metre';
        }

        if (old_unit && !this.state.q_unit)
            return newTotal;

        if (label === 'Qty' && this.state.old_q_unit === this.state.q_unit 
            && this.state.q_unit === '') {
            newTotal = Number(this.state.qty) + Number(this.state.old_qty);
        } else {
            let old = convertQty(this.state.old_qty, old_unit, this.state.q_unit);
            if (!isNaN(old)) {
                newTotal = Number(this.state.qty) + Number(old);
                newTotal = newTotal.toFixed(3) + ' ' + getUnitLabel(label.toLowerCase(), this.state.q_unit);
            } else {
                newTotal = "Invalid Unit for this item";
            }
        }

        return newTotal;
    }

    getQtyUnits = () => {
        if (this.state.qtyLabel === 'Length')
            return LENGTH_UNITS;
        else if (this.state.qtyLabel === 'Area')
            return AREA_UNITS;
        else
            return QTY_UNITS;
    }

    getOldQtyUnitOptions = () => {
        if (this.state.qtyLabel === 'Length' || this.state.qtyLabel === 'Area')
            return this.getQtyUnits();

        let units = [];

        if (!this.state.old_q_unit)
            return units;

        for (let i = 0; i < QTY_UNITS.length; i++) {
            if (unitsAreCompat(this.state.old_q_unit, QTY_UNITS[i].value)) {
                units.push(QTY_UNITS[i]);
            }
        }

        return units;
    }

    render() {
        return (
            <>
                <GenericForm 
                    isNew={this.props.initialData ? false : true}
                    permissions={this.props.permissions}
                    instance={this.props.instance}
                    entityId={this.props.initialData ? this.props.initialData.id : null}
                    api={this.props.api}
                    handleFormData={this.handleFormData}
                    afterSubmit={this.afterSubmit}
                    afterDelete={this.afterDelete}
                    afterCancel={this.afterDelete}
                    setErrors={this.setErrors}
                >
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-6">
                                <FormSelectInput 
                                    name="category"
                                    label="Category"
                                    defaultValue={this.state.category}
                                    error={this.state.errors.category}
                                    required={true}
                                    variant='fancy'
                                    options={this.props.categoryOptions}
                                    isLoading={this.props.categoryOptions.length === 0}
                                    onChangeCallback={this.onCategoryChange}
                                    readOnly={this.props.initialData && true}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <FormInput type="text" name="name" placeholder="Name" 
                                    label="Name"
                                    defaultValue={this.state.name}
                                    error={this.state.errors.name}
                                    required={true}
                                />
                            </div>
                        </div>

                        {this.state.categoryName && 
                        <div className="row">
                            <div className="col-lg-6">
                                {WIDTH_CATEGS.includes(this.state.categoryName) && 
                                    <StockInputGroup
                                        name="width"
                                        label="Width"
                                        value={this.state.width}
                                        onChange={this.onWidthChange}
                                        error={this.state.errors.width}
                                        required={true}
                                        unitOptions={LENGTH_UNITS}
                                        onUnitChange={this.onWidthUnitChange}
                                        unitValue={this.state.w_unit}
                                        unitError={this.state.errors.w_unit}
                                        readOnly={this.props.initialData && true}
                                        unitReadOnly={this.props.initialData && true}
                                    />
                                }

                                {this.state.old_qty !== '' && 
                                    <StockInputGroup
                                        name="old_qty"
                                        label={"Remaining " + this.state.qtyLabel}
                                        value={this.state.old_qty}
                                        unitOptions={this.getOldQtyUnitOptions()}
                                        onUnitChange={this.onOldQtyUnitChange}
                                        unitValue={this.state.old_q_unit}
                                        readOnly={true}
                                        unitReadOnly={this.state.old_q_unit ? false : true}
                                        isClearable={false}
                                    />
                                }
                            </div>
                        </div>
                        }

                        {this.state.categoryName &&
                        <div className="row">
                            {this.props.initialData &&
                                <div className="col-12">
                                    <h3 className="form-section-header">Add new stock</h3>
                                </div>
                            }

                            <div className="col-lg-6">
                                <StockInputGroup
                                    name="qty"
                                    label={this.state.qtyLabel}
                                    value={this.state.qty}
                                    onChange={this.onQtyChange}
                                    error={this.state.errors.qty}
                                    required={true}
                                    unitOptions={this.getQtyUnits()}
                                    onUnitChange={this.onQtyUnitChange}
                                    unitValue={this.state.q_unit}
                                    unitError={this.state.errors.q_unit}
                                />
                            </div>
                        </div>
                        }

                        {this.getNewTotal() !== null && 
                            <div className="row mt-1">
                                <div className="col-12">
                                    <p>Total after saving: <strong>{this.getNewTotal()}</strong></p>
                                </div>
                            </div>
                        }

                    </div>
                </GenericForm>
            </>
        );
    }
}

const StockItemForm = withRouter(_StockItemForm);



function StockInputGroup(props) {

    let isClearable = props.isClearable === undefined ? true : props.isClearable;

    return (
        <div className="row form-group-row">
            <div className="col-6">
                <FormInput 
                    name={props.name}
                    type="text" 
                    placeholder={props.label}
                    label={props.label}
                    value={props.value} 
                    onChange={props.onChange}
                    error={props.error}
                    required={props.required}
                    readOnly={props.readOnly}
                />
            </div>
            <div className="col-6">
                <FormSelectInput 
                    name={props.unitName}
                    label={" "}
                    defaultValue={props.unitValue} 
                    variant="fancy"
                    options={props.unitOptions}
                    onChangeCallback={props.onUnitChange}
                    error={props.unitError}
                    isClearable={isClearable}
                    readOnly={props.unitReadOnly}
                />
            </div>
        </div>
    );
}
